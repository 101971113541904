import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/proAccount.scss';

function ContactUs() {
  return (
    <div className="tariff-item-container tariff-container-border">
      <div className="tariff-pro-subject">
        <span className="font-24-reg">Enterprise</span>
        <div className="tariff-pro-tag">
          <span className="font-16-med">Individually</span>
        </div>
      </div>
      <div className="tariff-pro-title">
        <span className="font-18-bold tariff-item-title ">
          All like in Pro plus <br /> Individual conditions
        </span>
      </div>
      <div className="cursor-pointer font-16-bold tariff-choose-plan-contactUs">
        Contact us
        {SvgSelector('logo', 'logo-contact-us-icon')}
      </div>
      <span className="tariff-feature-text font-16-reg">
        We can help you integrate Layer into your processes. Our managers will
        help you sort out all the details
      </span>
    </div>
  );
}

export default ContactUs;
