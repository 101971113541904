import { starterFeature } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/proAccount.scss';
import Button from '../Elements/Button';

function StarterTariff() {
  return (
    <div className="tariff-item-container tariff-container-border">
      <span className="font-24-reg">Starter</span>
      <span className="font-h1 tariff-item-title">Free</span>
      <Button
        color="blue_light-2"
        image={SvgSelector('checkMark', 'checkMark-tariff-icon')}
        label="Activated now"
        textColor="blue_strong"
        buttonClass="tariff-active-button"
        height={44}
        labelClass="font-16-bold"
      />
      <span className="tariff-feature-text font-16-reg">Great plan to try</span>
      <span className="font-16-bold">Limits</span>
      <div className="tariff-feature-container">
        {starterFeature.map((i, index) => (
          <div
            className="tariff-feature-item"
            key={i.id}
            style={{ gap: index > 3 ? '20px' : '16px' }}
          >
            <div>
              {SvgSelector(
                `${index > 3 ? 'checkMark' : 'infinity'}`,
                `${index > 3 ? 'tariff-limit-icon' : 'tariff-limit-icon-infinity'}`,
              )}
            </div>
            <span className="font-16-reg">{i.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StarterTariff;
