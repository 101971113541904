/* eslint-disable no-param-reassign */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/taskBoard.scss';
import Avatar from '../Elements/Avatar';
import AnimationSlide from '../Elements/AnimationSlide';
import MenuSelectBoard from '../Elements/MenuSelectBoard';
import dataServices from '../../services/dataServices';
import TaskBoardMoreMenu from './TaskBoardMoreMenu';
import { colorPanel, floatMenuStatus } from '../../constants/arrays';
import Calendar from '../Calendar';
import StatusMenu from '../StatusMenu';
import { layerProps } from '../../constants/objects';
import { deadlineCal } from '../../helper/functions';
import MenuSelectMember from '../Elements/MenuSelectMember';
import MenuMemberList from '../Elements/MenuMemberList';
import TagMenu from '../Elements/TagMenu';
import { nodeColors } from '../../constants/colors';
import FloatMenuAssignment from '../Elements/FloatMenuAssignment';
import { DataContext } from '../../context/DataContext';
import Toast from '../Elements/Toast';
import ProOptionCard from '../ProAccount/ProOptionCard';
import ShareTagMenu from '../Elements/ShareTagMenu';

function TaskBoardCard({
  type = 'newTask',
  taskId,
  updateTask,
  updateTaskList,
  data,
  deleteTask,
  duplicateTask,
  changeStatus,
  treeData,
  openTaskChild,
  setOpenTaskChild,
  addChild,
  selectNode,
  dragRef,
  setCheckMembers,
  childCounter,
  boardId,
  filterResult,
}) {
  const { dispatch, setBlockParent, setRefresh, isProUser, setUnlockModal } =
    useContext(DataContext);
  const task = treeData[data];
  const user = JSON.parse(localStorage.getItem('user'));

  const commentsCounts = treeData[data]?.comments_count;
  const parentBoard = treeData[data]?.board_id
    ? treeData.find((i) => i.id === treeData[data].board_id)
    : null;
  const isNewTask = type === 'newTask';
  const isTreeTask = type === 'treeTask';
  const checkShowTag = (parentBoard && isNewTask) || isTreeTask;
  const navigate = useNavigate();
  const taskStatus = floatMenuStatus[task?.status];
  const [isDone, setIsDone] = useState(task?.status === 'DONE');
  const [openMenu, setOpenMenu] = useState(null);
  const [inputFocus, setInputFocus] = useState(false);
  const [taskName, setTaskName] = useState(task?.title[0].text);

  const tagColor =
    colorPanel.find(({ setColor }) => setColor === task?.color) || 'PINK';
  const [toastInfo, setToastInfo] = useState({
    text: null,
    persist: false,
  });
  const isFilter = filterResult && isTreeTask;

  const [findedBoardId, setFindedBoardId] = useState();
  const [deadline, setDeadline] = useState(task.due_time);
  const [assignedBoard, setAssignedBoard] = useState(parentBoard);
  const [statusIcon, setStatusIcon] = useState({
    icon: taskStatus?.label || 'status',
    color: taskStatus?.color || 'gray_06',
    value: taskStatus?.value || 'BACKLOG',
  });

  useEffect(() => {
    if (!inputFocus && task?.title[0].text !== taskName) {
      updateTask(task.id, { title: [{ text: taskName }] });
    }
  }, [inputFocus]);

  useEffect(() => {
    setIsDone(task.status === 'DONE');
    setTaskName(task?.title[0].text);
    setDeadline(task.due_time);
    setStatusIcon({
      icon: taskStatus?.label || 'status',
      color: taskStatus?.color || 'gray_06',
      value: taskStatus?.value || 'BACKLOG',
    });
    setAssignedBoard(parentBoard);
  }, [treeData, task, taskStatus]);

  const addTasksProject = (parentId) => {
    const newChild = {
      ...layerProps,
      id: uuidv4(),
      title: [{ text: 'TASKS' }],
      layer_type: 'TASK',
      color: 'PINK',
      parent: parentId,
      order: [],
    };
    dataServices.addLayers(newChild).then(
      setTimeout(() => {
        dataServices
          .relocate(task.id, {
            parent: newChild.id,
            previous: null,
          })
          .then(() =>
            setToastInfo({
              ...toastInfo,
              isLoading: false,
              text: 'Task Assigned',
              actionText: 'Okay',
              action: null,
            }),
          );
      }, [300]),
    );
  };

  const selectBoard = (id) => {
    updateTaskList(task.id, { board_id: id });
    setRefresh((old) => old + 1);
    setOpenMenu('');
    setAssignedBoard(treeData.filter((i) => i.id === id)[0]);
    dataServices.getLayerById(id).then((layer) => {
      if (layer.data.order.length) {
        dataServices
          .getLayerChildren(id, {
            layer_type__in: 'TASK',
          })
          .then((children) => {
            const firstProject = children.data.find(
              (i) => i.id === layer.data.order[0],
            );
            if (firstProject.title[0].text === 'TASKS') {
              dataServices
                .relocate(task.id, {
                  parent: firstProject.id,
                  previous: null,
                })
                .then(() => {
                  setToastInfo({
                    ...toastInfo,
                    isLoading: false,
                    text: 'Task Assigned',
                    actionText: 'Okay',
                    action: null,
                  });
                });
            } else addTasksProject(id);
          });
      } else addTasksProject(id);
    });
  };

  const warnIfNotAssigned = () => {
    if (!assignedBoard)
      setToastInfo({
        text: 'Before doing such action, assign a board to task!',
        actionText: 'Ok',
      });
  };

  const setDate = (startDate, dueDate, reminder) => {
    setDeadline(dueDate);
    updateTaskList(
      task.id,
      {
        due_time: dueDate,
        start_time: startDate,
        reminder,
      },
      'Deadline',
    );
  };
  const selectStatus = (item) => {
    setOpenMenu(null);
    setStatusIcon({ icon: item.label, color: item.color, value: item.value });
    if (item.label === 'done') setIsDone(true);
    else setIsDone(false);
    changeStatus(task.id, item.value);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setInputFocus(false);
    }
  };

  const openComment = (e) => {
    if (user) {
      e.stopPropagation();
      selectNode(data);
      dispatch({ type: 'showComment', payload: true });
    } else {
      setUnlockModal(true);
    }
  };

  return (
    <div
      disabled={isFilter}
      className={` task-board-card-container cursor-pointer`}
      ref={dragRef}
      style={{ opacity: isFilter ? '0.2' : '1' }}
    >
      <div
        className="task-board-card-name-container task-board-card-name-container-fixed-width"
        style={{ paddingLeft: childCounter * 20 }}
      >
        {isTreeTask && (
          <div
            onClick={(e) => {
              setOpenTaskChild(!openTaskChild);
              e.preventDefault();
            }}
            className="tooltip"
          >
            {treeData[data]?.order?.length > 0 &&
              SvgSelector(
                'toggleArrow',
                `${
                  openTaskChild
                    ? 'task-board-arrow-open-child'
                    : 'task-board-arrow-icon'
                }`,
              )}
            <span className="tooltiptext task-board-sub-tool font-10-med">
              Subtasks
            </span>
          </div>
        )}
        <div
          className={`${isDone ? 'add-task-done-check' : 'add-task-check'}`}
          onClick={() =>
            selectStatus(
              isDone ? floatMenuStatus.BACKLOG : floatMenuStatus.DONE,
            )
          }
        >
          {SvgSelector(
            'checkMark',
            `${
              isDone
                ? 'add-task-done-supercheck-icon'
                : 'add-task-supercheck-icon'
            }`,
          )}
        </div>
        <div className="task-board-card-title-input">
          {/* <Emoji /> */}
          {inputFocus ? (
            <input
              type="text"
              value={taskName}
              className="task-board-card-name font-14-med"
              onChange={(e) => setTaskName(e.target.value)}
              onBlur={() => setInputFocus(false)}
              onKeyDown={onKeyDown}
              placeholder="Add Text"
            />
          ) : (
            <div
              className="task-board-card-blur-title font-14-med"
              onClick={(e) => {
                setInputFocus(true);
                e.preventDefault();
              }}
              style={{ textDecoration: isDone ? 'line-through' : 'none' }}
            >
              {taskName || (
                <div className="font-14-med task-board-card-empty-name">
                  Add Text
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isNewTask && (
        <div className="task-board-title-container">
          <div>
            {!assignedBoard ? (
              <div>
                <div
                  className="font-14-med task-board-card-title tooltip"
                  onClick={() => setOpenMenu('board')}
                >
                  Board
                  {SvgSelector('chevronDown', 'card-exist-board-icon')}
                  <span className="tooltiptext font-10-med task-board-project-tool task-board-new-project-tool">
                    Select board
                  </span>
                </div>
                <AnimationSlide
                  openMenu={setOpenMenu}
                  show={openMenu === 'board'}
                  state="topToDown"
                >
                  <MenuSelectBoard
                    selectBoard={selectBoard}
                    setFindedBoardId={setFindedBoardId}
                  />
                </AnimationSlide>
              </div>
            ) : (
              <div
                className="font-14-med task-board-card-title tooltip"
                onClick={() => navigate(`/${assignedBoard.id}`)}
              >
                {SvgSelector('chevronRight', 'cehvron-right-icon')}
                <div className="task-board-card-title font-14-med">
                  {assignedBoard.title[0]?.text}
                </div>
                <span className="tooltiptext font-10-med task-board-project-tool">
                  Open project
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className="task-board-card-date font-14-med tooltip"
        onClick={() => setOpenMenu('date')}
      >
        <div>
          {!deadline ? (
            <div className="task-board-card-new-date">
              Deadline
              {SvgSelector('chevronDown', 'card-date-new-icon')}
            </div>
          ) : (
            <div
              className="task-board-card-exist-date"
              style={{
                color:
                  deadlineCal(deadline)[1] === 'alert'
                    ? 'var(--red)'
                    : 'var(--gray_01)',
              }}
            >
              {deadlineCal(deadline)[0]}
              {SvgSelector('chevronDown', 'card-date-new-icon')}
            </div>
          )}
          <AnimationSlide
            openMenu={setOpenMenu}
            show={openMenu === 'date'}
            state="topToDown"
          >
            {isProUser ? (
              <div style={{ position: 'absolute', top: 15, right: -14 }}>
                <Calendar
                  toggle={() => setOpenMenu(null)}
                  node={task}
                  directReq={isNewTask}
                  callback={setDate}
                />
              </div>
            ) : (
              <div className="task-board-pro-card-pos">
                <ProOptionCard
                  closeMenu={setOpenMenu}
                  proText="Want to add deadline? Sign up for a tariff level right now!"
                />
              </div>
            )}
          </AnimationSlide>
        </div>

        <span className="font-10-med tooltiptext task-board-card-date-tool">
          Change deadline
        </span>
      </div>
      <div
        className="task-board-card-status-container tooltip"
        onClick={() => setOpenMenu('status')}
      >
        <div
          className="task-card-status-new-icon-container tooltip"
          style={{ backgroundColor: `var(--${statusIcon.color})` }}
          // onClick={() =>
          //   !assignedBoard ? warnIfNotAssigned() : setOpenMenu('tag')
          // }
        >
          {SvgSelector(statusIcon.icon, `status-icon`)}
        </div>
        {SvgSelector(
          'chevronDown',
          `card-status-icon ${isNewTask ? 'card-status-new-icon' : ''}`,
        )}
        <span className="font-10-med card-status-tool tooltiptext">
          Edit status
        </span>
        <AnimationSlide
          openMenu={setOpenMenu}
          show={openMenu === 'status'}
          state="topToDown"
        >
          <div className="task-board-status-menu">
            <StatusMenu state={statusIcon.value} setState={selectStatus} />
          </div>
        </AnimationSlide>
      </div>
      <div
        className="task-board-tag-container tooltip"
        onClick={() =>
          !assignedBoard && isNewTask
            ? warnIfNotAssigned()
            : setOpenMenu(`${user ? 'tag' : 'anonymousTag'}`)
        }
      >
        {checkShowTag ? (
          <div
            className="task-board-new-tag font-14-med"
            style={{
              backgroundColor: nodeColors[tagColor.setColor],
              borderRadius: 3,
              paddingRight: 5,
              paddingLeft: 5,
            }}
          >
            {isNewTask
              ? parentBoard[tagColor.color]
              : treeData[boardId][tagColor.color]}
          </div>
        ) : (
          <div className="task-board-tag font-14-med">Tag</div>
        )}
        {SvgSelector('chevronDown', 'card-tag-icon')}
        <span className="font-10-med card-tag-tool tooltiptext">{`${
          isNewTask ? 'Add tag' : 'Edit tag'
        }`}</span>
        <AnimationSlide
          openMenu={setOpenMenu}
          show={openMenu === 'tag'}
          state="topToDown"
        >
          <div className="task-board-tag-menu">
            <TagMenu task={data} directReq={false} />
          </div>
        </AnimationSlide>
        <AnimationSlide
          openMenu={setOpenMenu}
          show={openMenu === 'anonymousTag'}
          state="topToDown"
        >
          <div className="task-board-tag-menu">
            <ShareTagMenu task={data} />
          </div>
        </AnimationSlide>
      </div>
      <div className="task-board-members-container tooltip">
        {!task?.assignments?.length > 0 ? (
          <div>
            <div
              className="task-board-new-members font-14-med"
              onClick={() =>
                user
                  ? parentBoard || isTreeTask
                    ? setOpenMenu('newMembers')
                    : warnIfNotAssigned()
                  : setUnlockModal(true)
              }
            >
              Members
            </div>
          </div>
        ) : (
          <div
            className="task-board-exist-members"
            onClick={() => setOpenMenu('oldMember')}
          >
            {task?.assignments?.slice(0, 3).map((i) => (
              <Avatar key={i.id} size={24} name={i.email} />
            ))}
            {task.assignments?.length > 3 && (
              <div className="task-board-card-title-member-more cursor-pointer font-12-med">
                +{task.assignments.length - 3}
              </div>
            )}
          </div>
        )}
        <div
          onClick={() =>
            parentBoard || isTreeTask ? setOpenMenu('newMembers') : null
          }
        >
          {SvgSelector('chevronDown', 'card-member-icon')}
        </div>

        <AnimationSlide
          openMenu={setOpenMenu}
          show={openMenu === 'newMembers'}
          state="topToDown"
        >
          {isNewTask ? (
            <div className="task-board-select-member-menu-pos">
              <MenuSelectMember
                taskId={taskId}
                data={assignedBoard?.members}
                taskData={task}
                setOpenMenu={setOpenMenu}
                updateTaskList={updateTaskList}
                setCheckMembers={setCheckMembers}
                type="filter"
              />
            </div>
          ) : (
            <div className="task-board-assign-member">
              <FloatMenuAssignment layer={treeData[data]} />
            </div>
          )}
        </AnimationSlide>

        <AnimationSlide
          openMenu={setOpenMenu}
          show={openMenu === 'oldMember'}
          state="topToDown"
        >
          <div className="task-board-more-member-pos">
            <MenuMemberList taskId={task.id} setOpenMenu={setOpenMenu} />
          </div>
        </AnimationSlide>
      </div>

      {isTreeTask && (
        <div className="task-view-buttons">
          <div
            className="tree-node-has-note task-board-comment-container tooltip"
            onClick={(e) => openComment(e)}
          >
            {commentsCounts !== 0 && commentsCounts !== undefined && (
              <div className="budget-comments budget-comments-first-level">
                {commentsCounts > 9 ? '+9' : commentsCounts}
              </div>
            )}
            {SvgSelector('comment', 'task-board-comment-icon')}
            <div className="tooltiptext font-10-med card-comment-tool">
              Comment
            </div>
          </div>
          <div
            className="tooltip task-board-pencil-container"
            onClick={() => {
              setBlockParent(task.id);
              dispatch({ type: 'boardToggle', payload: true });
            }}
          >
            {SvgSelector('pencil', 'task-board-pencil-icon')}
            <div className="tooltiptext font-10-med card-pen-tool">Note</div>
          </div>
        </div>
      )}
      <div>
        <div className="task-board-card-more-icon-wrapper">
          {isTreeTask && (
            <div
              className="task-board-card-plus-wrapper tooltip"
              onClick={() => addChild()}
            >
              {SvgSelector('plus', 'task-board-card-plus-icon cursor-pointer')}
              <span className="tooltiptext font-10-med card-sub-tool">
                Add subtask
              </span>
            </div>
          )}
          <div
            className="task-board-more-wrapper"
            onClick={() => setOpenMenu('more')}
          >
            {SvgSelector('moreVertical', 'task-board-more-icon')}
          </div>
        </div>
        <AnimationSlide
          state="topToDown"
          show={openMenu === 'more'}
          openMenu={setOpenMenu}
        >
          <div
            className={`${isTreeTask ? 'task-more-menu-pos' : 'task-more-menu-pos-new'}`}
          >
            <TaskBoardMoreMenu
              deleteTask={deleteTask}
              duplicateTask={duplicateTask}
              taskId={data}
              findedBoardId={findedBoardId}
              type={type}
              setOpenMenu={setOpenMenu}
              openMenu={openMenu}
              changeStatus={selectStatus}
              name={task?.title[0].text}
              data={task}
              statusIcon={statusIcon}
              assignedBoard={assignedBoard}
              updateTaskList={updateTaskList}
              setDate={setDate}
              warnIfNotAssigned={warnIfNotAssigned}
            />
          </div>
        </AnimationSlide>
      </div>
      {toastInfo.text && (
        <Toast toastInfo={toastInfo} setToastInfo={setToastInfo} />
      )}
    </div>
  );
}

export default TaskBoardCard;
