import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/proAccount.scss';
import dataServices from '../../services/dataServices';

const starterFeature = [
  { id: 1, text: 'Nodes is unlimited' },
  { id: 2, text: 'Transform file is unlimited' },
  { id: 3, text: '100 members available' },
];

const specialProFeature = [
  { id: 1, text: 'AI Assistant' },
  { id: 2, text: 'Deadline and calendar' },
  { id: 3, text: '“Notion like” editor' },
  { id: 4, text: 'Export all to files' },
  { id: 5, text: 'Upload your, pdf, excel, doc, files' },
  { id: 6, text: 'Smart task filters' },
  { id: 7, text: 'Text tags' },
  { id: 8, text: 'Lock/unlock trees' },
];

function ProTariff({ data, setIsLoading }) {
  const calculatePrice = data.price_in_cents / 100;
  const calculateTime = Math.floor(data.duration_in_days / 30);
  const calculatePerMonth = calculateTime < 2 ? '' : ` ${calculateTime}`;

  const selectPlan = () => {
    setIsLoading(true);
    const params = {
      plan: data.id,
    };

    dataServices.payment(params).then((res) => {
      setIsLoading(false);
      window.location.href = res.data.url;
    });
  };

  return (
    <div className="gradient-border">
      <div className="tariff-item-container">
        <div className="tariff-pro-subject">
          <span className="font-24-reg">PRO</span>
          <div className="tariff-pro-tag tooltip cursor-pointer">
            {SvgSelector('starsFull')}
            <span className="font-16-med">AI Assist</span>
            <span className="tooltiptext font-10-med tariff-pro-tag-tooltip">
              Helps scale up your project
              <span className="tariff-pro-tag-tooltip-color">PRO</span>
            </span>
          </div>
        </div>
        <div className="tariff-pro-title">
          <span className="font-h1 tariff-item-title">{`$ ${calculatePrice}`}</span>
          <span className="font-18-med tariff-pro-title-time">
            /{`${calculatePerMonth} month`}
          </span>
        </div>
        <div
          className="cursor-pointer tariff-choose-plan"
          onClick={() => selectPlan()}
        >
          <span className="font-16-bold" style={{ color: 'var(--white)' }}>
            Select plan
          </span>
        </div>
        <span className="tariff-feature-text font-16-reg">
          Everything in Starter and...
        </span>
        <span className="font-16-bold">Limits</span>
        <div className="tariff-feature-container">
          {starterFeature.map((i, index) => (
            <div
              className="tariff-feature-item"
              key={i.id}
              style={{ gap: index > 1 ? '20px' : '16px' }}
            >
              <div>
                {SvgSelector(
                  `${index > 1 ? 'checkMark' : 'infinity'}`,
                  `${index > 1 ? 'tariff-limit-icon' : 'tariff-limit-icon-infinity'}`,
                )}
              </div>
              <span className="font-16-reg">{i.text}</span>
            </div>
          ))}
        </div>

        <span className="font-16-bold tariff-feature-md-margin">
          Special features
        </span>
        <div className="tariff-feature-container">
          {specialProFeature.map((i, index) => (
            <div
              className="tariff-feature-item"
              style={{ gap: index !== 0 ? '20px' : '16px' }}
            >
              <div>
                {SvgSelector(
                  `${index !== 0 ? 'checkMark' : 'starsFull'}`,
                  `${index !== 0 ? 'tariff-limit-icon' : 'tariff-limit-icon-infinity'}`,
                )}
              </div>
              <span className="font-16-reg">{i.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProTariff;
