import { useState } from 'react';
import AnimationSlide from './AnimationSlide';
import Avatar from './Avatar';
import { accessList, accessName } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import dataServices from '../../services/dataServices';

function ShareMemberCard({ email, privilege, node }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [shareAccess, setShareAccess] = useState({
    name: accessName[privilege],
    value: privilege,
  });

  const changePrivilege = (i) => {
    const data = {
      changes: [
        {
          email,
          privilege: i.value,
        },
      ],
    };
    dataServices.multiInvite(node, data).then(() => {
      setShareAccess({ name: i.text, value: i.value });
    });
  };
  return (
    <div className="modal-sharing-invite-list-item-container hover">
      <div className="invite-list-item-name">
        <div>
          <Avatar size={24} />
        </div>
        <div className="font-14-reg">{email}</div>
      </div>
      <div
        className="cursor-pointer copy-link-text-access font-14-med"
        onClick={() => setOpenMenu(true)}
      >
        <span className="copy-link-access-text">{shareAccess.name}</span>
        <div className="copy-link-icon-link align-center">
          {SvgSelector('chevronDown')}
        </div>
        <AnimationSlide
          show={openMenu}
          openMenu={setOpenMenu}
          state="topToDown"
        >
          <div className="copy-link-change-access cursor-pointer">
            {accessList.map((i) => (
              <div
                className="copy-link-access-item"
                onClick={() => changePrivilege(i)}
              >
                <div className="copy-link-access-icon-container">
                  <div>{SvgSelector(`${i.icon}`, 'copy-link-access-icon')}</div>
                  <span>{i.text}</span>
                </div>
                {i.value === shareAccess.value && (
                  <div>{SvgSelector('checkMark')}</div>
                )}
              </div>
            ))}
          </div>
        </AnimationSlide>
      </div>
      {/* <span className="font-14-reg invite-list-item-text">owner</span> */}
    </div>
  );
}

export default ShareMemberCard;
