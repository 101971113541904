import { useEffect, useState, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import Avatar from '../Elements/Avatar';
import TextInput from '../Elements/TextInput';
import Button from '../Elements/Button';
import { SvgSelector } from '../../helper/svg-selector';
import { DataContext } from '../../context/DataContext';
import accountServices from '../../services/accountServices';

function AccountTab() {
  const user = JSON.parse(localStorage.getItem('user'));
  const { openAccountTab } = useContext(DataContext);
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    current: '',
    order: '',
    pk: '',
    root: '',
    oldPass: '',
    newPass1: '',
    newPass2: '',
  });

  const handleOnChange = (text, input) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  const saveData = () => {
    setLoading(true);
    const data = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      company_name: inputs.company_name,
      current: inputs.current,
    };
    accountServices.updateUser(data).then((res) => {
      localStorage.setItem('user', JSON.stringify({ ...user, ...res.data }));
      if (inputs.oldPass || inputs.newPass1 || inputs.newPass2)
        changePassword();
      else setLoading(false);
    });
  };

  const changePassword = () => {
    const data = {
      password: inputs.oldPass,
      password1: inputs.newPass1,
      password2: inputs.newPass2,
    };
    accountServices.changePassword(data).then(() => setLoading(false));
  };

  useEffect(() => {
    setInputs((old) => ({ ...old, ...user }));
  }, []);

  useEffect(() => {
    if (!openAccountTab) setLoading(false);
  }, [openAccountTab]);

  const logOut = () => {
    window.location.href = '/accounts/logout/';
    localStorage.clear();
  };

  return (
    <div className="tab-container">
      <div className="font-16-bold">Avatar</div>
      <div className="tab-avatar-container">
        <Avatar size={64} font="font-24-med" name={inputs.email} />
        {/* <div className="tab-avatar-details font-12-reg">
          <div>Recommend a 1080 x 1080</div>
          <div>image in jpg, png format</div>
          <div className="tab-avatar-accent-text font-14-med">Upload photo</div>
        </div> */}
      </div>
      <div className="tab-account-info-container">
        <div className="tab-account-info-title font-16-bold">
          Account information
        </div>
        <div className="tab-account-rows">
          <TextInput
            title="Your name"
            value={inputs.first_name}
            input="first_name"
            placeholder="Enter your name"
            handleChange={handleOnChange}
            width="32.2%"
          />
          <TextInput
            title="Your surname"
            value={inputs.last_name}
            input="last_name"
            placeholder="Enter your surname"
            handleChange={handleOnChange}
            width="32.2%"
          />
          <TextInput
            title="Email / login"
            value={inputs.email}
            readOnly
            input="email"
            placeholder="Email address"
            handleChange={handleOnChange}
            width="32.2%"
          />
        </div>
        <div className="tab-account-info-title font-16-bold">
          System information
        </div>
        <div className="tab-account-rows">
          <TextInput
            title="Company name"
            value={inputs.company_name}
            input="company_name"
            placeholder=""
            handleChange={handleOnChange}
            width="49%"
          />
          <TextInput
            title="Current"
            value={inputs.current}
            input="current"
            placeholder=""
            handleChange={handleOnChange}
            width="49%"
          />
        </div>
        <div className="tab-account-info-title font-16-bold">
          Account information
        </div>
        <div className="tab-account-rows">
          <TextInput
            title="Old password"
            value={inputs.oldPass}
            input="oldPass"
            placeholder="Enter a password"
            handleChange={handleOnChange}
            width="32.2%"
          />
          <TextInput
            title="New password"
            value={inputs.newPass1}
            input="newPass1"
            placeholder="Enter a password"
            handleChange={handleOnChange}
            width="32.2%"
          />
          <TextInput
            title="Repeat new password"
            value={inputs.newPass2}
            input="newPass2"
            placeholder="Enter a password"
            handleChange={handleOnChange}
            width="32.2%"
          />
        </div>
        {/* <div className="tab-avatar-accent-text font-14-med account-tab-forget">
          Forgot password
        </div> */}
        <div>
          <Button
            style={{ marginTop: 20, paddingLeft: 12 }}
            label="Save"
            width={100}
            image={
              isLoading ? (
                <div className="loading-spinner-account loading-wrapper-account">
                  {SvgSelector('spinner', 'loading-page-spinner-account')}
                </div>
              ) : null
            }
            labelClass="font-14-med"
            color="blue_strong"
            textColor="white"
            onClick={isLoading ? null : saveData}
          />
        </div>
      </div>
      <div className="tab-account-rest">
        <div
          className="tab-avatar-accent-text font-14-med hover"
          onClick={() => logOut()}
        >
          Log out
        </div>
        {/* <div className="tab-avatar-danger-text font-14-med account-tab-delete hover">
          Delete account
        </div> */}
      </div>
      <ToastContainer hideProgressBar closeButton />
    </div>
  );
}
export default AccountTab;
