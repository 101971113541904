import { useContext, useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/menuMember.css';
import dataServices from '../../services/dataServices';
import AnimationSlide from './AnimationSlide';
import ModalAssign from './ModalAssign';
import Avatar from './Avatar';
import { DataContext } from '../../context/DataContext';
import ProOptionCard from '../ProAccount/ProOptionCard';

let originData;
export default function MenuSelectMember({
  data,
  setAssignMember,
  taskData,
  setFilterTask,
  setCheckMembers,
  type = 'notFilter',
}) {
  const [searchContent, setSearchContent] = useState('');
  const [items, setItems] = useState([data?.assignments || []]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [privilege, setPrivilege] = useState('READ');
  const { treeData, dispatch, isProUser } = useContext(DataContext);

  useEffect(() => {
    originData = data;
  });

  useEffect(() => {
    if (!searchContent || searchContent.length === 0) {
      setItems(originData);
      return;
    }
    let tempData = [...originData];
    tempData = tempData?.filter((item) =>
      item?.email?.toLowerCase().includes(searchContent.toLowerCase()),
    );
    setItems(tempData);
  }, [searchContent]);

  const limitMember = () => {
    if (isProUser || treeData[taskData.id].assignments.length < 5) {
      setOpenModal('openModal');
      return true;
    }
    if (!isProUser && treeData[taskData.id].assignments.length >= 5) {
      setOpenModal('membersPro');
      return false;
    }
    return null;
  };

  const inviteNewMember = () => {
    if (limitMember()) {
      setOpenModal('openModal');
      return;
    }
    if (!limitMember()) {
      setOpenModal('membersPro');
    }
  };

  const assignMember = (user) => {
    setOpenModal(false);
    if (setFilterTask) {
      setFilterTask(user);
    } else {
      const alreadyAssigned = taskData.assignments?.find(
        (member) => member.id === user.id,
      );
      if (alreadyAssigned) return;
      setLoading(true);
      const assignParams = {
        data: {
          assignment: true,
          member: user.id,
          privilege,
        },
        pks: [taskData.id],
      };
      const newAssignments = items;
      const alreadySharedUser = newAssignments.find(
        (member) => member.id === user.id,
      );
      if (!alreadySharedUser) newAssignments.push(user);
      treeData[taskData.id].assignments.push(user);
      setItems(newAssignments);
      dispatch({ type: 'setTree', payload: treeData });

      dataServices.assignMultiTasks(assignParams).then(() => {
        // updateTaskList(taskData.id, {
        //   assignments: [...taskData.assignments, user],
        // });
        if (type === 'filter') setCheckMembers((prev) => !prev);
        setLoading(false);
        setOpenModal(false);
      });
    }
  };

  const easyAssign = (user) => {
    if (limitMember()) {
      assignMember(user);
      return;
    }
    if (limitMember()) {
      setOpenModal('membersPro');
    }
  };
  return (
    <div>
      <div className="task-board-card-member-container box-shadow">
        <div className="flex-row task-board-card-member-title">
          <div className="font-14-med">Select board</div>
          <div className="font-14-med task-board-card-member-clear">Clear</div>
        </div>
        <div className="task-board-card-member-search">
          {SvgSelector('search', 'svgColor')}
          <input
            className="font-14-reg task-board-card-member-search-input"
            value={searchContent}
            onChange={(e) => setSearchContent(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div className="modal-menu-member-more-items-wrapper task-board-members-list-container">
          {!setFilterTask && (
            <div
              className="font-14-med invite-member-task-container cursor-pointer"
              onClick={() => inviteNewMember()}
            >
              {SvgSelector('plus', 'invite-member-task-icon')}
              <div className="invite-member-task">Invite a member</div>
            </div>
          )}
          {items?.map((i) => {
            if (i.privilege === 0) return null;
            return (
              <div
                key={i.id}
                className="hover modal-member-list-invite-container cursor-pointer"
                onClick={() => easyAssign(i)}
              >
                <div className="flex-row modal-member-list-invite">
                  <Avatar size={24} name={i.email} />
                  <div>
                    {i.first_name && i.last_name ? (
                      <div className="font-14-reg">{`${i.first_name} ${i.last_name}`}</div>
                    ) : (
                      <div className="font-14-reg">{i.email}</div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {!setFilterTask && (
          <AnimationSlide
            openMenu={setOpenModal}
            show={openModal === 'openModal'}
            state="topToDown"
          >
            <ModalAssign
              sendLink={taskData.layer_type === 'TASK'}
              onAssign={setAssignMember}
              setItems={setItems}
              assignMember={assignMember}
              isLoading={isLoading}
              setLoading={setLoading}
              privilege={privilege}
              setPrivilege={setPrivilege}
              openModal={setOpenModal}
            />
          </AnimationSlide>
        )}
      </div>
      <AnimationSlide
        openMenu={setOpenModal}
        show={openModal === 'membersPro'}
        state="rightToLeft"
      >
        <div className="invite-member-pro-pos">
          <ProOptionCard
            closeMenu={setOpenModal}
            proText="Only 5 members. Use PRO without restrictions!"
          />
        </div>
      </AnimationSlide>
    </div>
  );
}
