import { useContext, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import CardBoard from './CardBoard';
import DashboardHeader from './DashboardHeader';
import ControlTabs from '../Elements/ControlTabs';
import { tabsBoardItem } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import UploadingCard from './UploadingCard';
import { DataContext } from '../../context/DataContext';

let objectsOrder = [];
function DashboardBody({
  data,
  changeOrder,
  currentProject,
  reOrder,
  deletedScreen,
  deleteForEverData,
  unDeleteData,
  sharedScreen,
  moveInProject,
  duplicateCard,
  viewType,
  updateData,
  deleteData,
  isFocus,
  setFocus,
  searchContent,
  openInviteModal,
  openBoard,
  RefreshLayer,
  favHandler,
  isLoading,
  selectedItems,
  updateSelectedItems,
  changedTab,
  setChangedTab,
  dashboardType,
  checkedShared,
  selectedTab,
  updateObjAssignment,
  setUpdateObjAssignment,
  inviteModalShown,
  layerToShare,
  showInviteModal,
  setToastInfo,
  toastInfo,
  setLayerToShare,
  assignment,
  dataObject,
  showSharedTabs = false,
  uploadingFile,
  RootId,
  SharedNotification,
  openProject,
  hasMore,
}) {
  const { loadingPage } = useContext(DataContext);
  const [filteredData, setFilteredData] = useState(null);
  const [sortedItem, setSortedItem] = useState(null);
  objectsOrder = [];
  const updateItem = (id, params) => {
    updateData(id, params);
  };

  const reOrderItems = (from, to) => {
    let tempOrder = [];
    tempOrder = objectsOrder;
    const item = tempOrder.splice(from, 1)[0];
    tempOrder.splice(to, 0, item);
    reOrder(
      tempOrder,
      to === 0 ? null : objectsOrder[to - 1],
      objectsOrder[to],
    );
    setSortedItem(null);
  };

  useEffect(() => {
    if (!searchContent || searchContent.trim().length === 0) {
      setFilteredData(data);
      return;
    }
    let tempData = [...data];
    tempData = tempData?.filter(
      (item) =>
        item.title[0].text
          .toLowerCase()
          .indexOf(searchContent.toLowerCase()) !== -1,
    );
    setFilteredData(tempData);
  }, [searchContent, data]);
  const checkRecentCard =
    RootId !== currentProject?.id && dashboardType === 'Recent';
  if (filteredData?.length === 0)
    return (
      dashboardType === 'All' && (
        <div className="project-container">
          <div className="font-18-bold">
            {deletedScreen ? 'Deleted' : 'All objects'}
          </div>
          {!deletedScreen && showSharedTabs && (
            <div className="project-container-position">
              <ControlTabs
                changeTab={changedTab}
                setChangeTab={setChangedTab}
                listTab={tabsBoardItem}
                length={SharedNotification}
              />
            </div>
          )}
          <div className="project-container-empty-shared">
            {SvgSelector('happyMan')}
            <div className="font-h2">
              You have’t{' '}
              {!deletedScreen
                ? changedTab === 'all'
                  ? 'objects'
                  : 'public boards'
                : 'deleted objects'}
            </div>
          </div>
        </div>
      )
    );
  if (
    filteredData?.filter((i) => i.parent === currentProject.id)?.length > 0 ||
    checkedShared ||
    deletedScreen
  ) {
    return (
      <div
        className={`${!checkRecentCard ? 'project-container' : 'project-container-ai'}`}
      >
        {!checkRecentCard && (
          <div>
            {sharedScreen ? (
              <div className="font-18-bold">
                {`${dashboardType === 'Recent' ? 'Recent boards' : 'All objects'}`}
              </div>
            ) : deletedScreen ? (
              <div className="font-18-bold">Deleted</div>
            ) : (
              <div className="font-18-bold">
                {`${dashboardType === 'Recent' ? 'Recent boards' : 'All objects'}`}
              </div>
            )}
            {dashboardType === 'All' && selectedTab !== 4 && showSharedTabs && (
              <div className="project-container-position">
                <ControlTabs
                  changeTab={changedTab}
                  setChangeTab={setChangedTab}
                  listTab={tabsBoardItem}
                  length={SharedNotification}
                />
              </div>
            )}
            {viewType === 'lineageView' && (
              <DashboardHeader
                sortedItem={sortedItem}
                setSortedItem={setSortedItem}
                sharedScreen={sharedScreen}
                deletedScreen={deletedScreen}
                changedTab={changedTab}
              />
            )}
          </div>
        )}
        <div className="project-table card-body-container">
          {orderBy(
            filteredData,
            [sortedItem],
            [sortedItem === 'title[0].text' ? 'asc' : 'desc'],
          )?.map((item, index) => {
            objectsOrder.push(item.id);
            if (item.type !== 'UPLOADING' && !checkRecentCard) {
              return (
                <CardBoard
                  canDrag={
                    dashboardType === 'Recent' || changedTab !== 'all'
                      ? false
                      : !(deletedScreen || sharedScreen)
                  }
                  key={item.id}
                  type={item.layer_type}
                  cardItem={item}
                  viewType={viewType}
                  index={index}
                  selectedItems={selectedItems}
                  updateSelectedItems={updateSelectedItems}
                  duplicateCard={duplicateCard}
                  isFocus={item.id === isFocus}
                  setFocus={setFocus}
                  reOrder={reOrderItems}
                  updateData={updateData}
                  deletedScreen={deletedScreen}
                  deleteForEverData={deleteForEverData}
                  unDeleteData={unDeleteData}
                  sharedScreen={sharedScreen}
                  limited={sharedScreen || deletedScreen}
                  deleteData={deleteData}
                  changeOrder={changeOrder}
                  openBoard={openBoard}
                  updateItem={updateItem}
                  moveInProject={moveInProject}
                  openInviteModal={openInviteModal}
                  id={item.id}
                  layerType={item.layer_type}
                  moveCard={null}
                  RefreshLayer={RefreshLayer}
                  favHandler={favHandler}
                  isLoading={isLoading}
                  updateObjAssignment={updateObjAssignment}
                  setUpdateObjAssignment={setUpdateObjAssignment}
                  inviteModalShown={inviteModalShown}
                  layerToShare={layerToShare}
                  showInviteModal={showInviteModal}
                  setToastInfo={setToastInfo}
                  toastInfo={toastInfo}
                  setLayerToShare={setLayerToShare}
                  assignment={assignment}
                  dataObject={dataObject}
                  openProject={openProject}
                  changedTab={changedTab}
                />
              );
            }
            if (item.type === 'UPLOADING') {
              return (
                <UploadingCard uploadingFile={uploadingFile} data={item} />
              );
            }
            return null;
          })}
        </div>
        {loadingPage && dashboardType === 'All' ? (
          <div style={{ marginInline: '50%', paddingTop: '10px' }}>
            <div className="loading-spinner loading-wrapper">
              {SvgSelector('spinner', 'loading-page-spinner')}
            </div>
          </div>
        ) : null}

        {hasMore && (
          <div className="font-12-reg no-more-data-list">No More Data</div>
        )}
      </div>
    );
  }
  return null;
}
export default DashboardBody;
