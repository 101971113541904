import { SvgSelector } from '../../helper/svg-selector';
import { exportItems } from '../../constants/arrays';
import Button from './Button';

function ModalExport({ selectItem, selectedItem, exportHandler, isLoading }) {
  return (
    <div
    // className="modal-container"
    // aria-hidden="true"
    // onClick={(event) => {
    //   event.preventDefault();
    //   if (event.target === event.currentTarget) {
    //     openModal(false);
    //   }
    // }}
    >
      <div className="modal-sharing-export-container">
        <div className="modal-export-download-container">
          {exportItems.map((item) => (
            <div
              className="flex-row cursor-pointer modal-export-download-check"
              onClick={() => selectItem(item)}
            >
              {SvgSelector(
                item.id === selectedItem.id ? 'radioBtnFill' : 'radioBtn',
                'modal-export-radio-icon',
              )}
              <img
                src={item.src}
                width="30"
                height="30"
                alt=""
                className="modal-export-icons"
              />
              <div className="font-14-reg modal-export-download-text">
                {item.title}{' '}
                <span className="modal-export-download-name font-12-reg">
                  {item.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-sharing-buttons">
        <div />
        <Button
          color="blue_light-2"
          height={36}
          label="Export"
          textColor="blue_strong"
          labelClass="font-14-bold"
          onClick={exportHandler}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
export default ModalExport;
