import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/confirmDeleteModal.scss';
import Button from './Button';

function ConfirmDeleteModal({ setOpenModal, deleteData, dataCard, multi }) {
  return (
    <div
      className="modal-container"
      aria-hidden="true"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setOpenModal(false);
        }
      }}
    >
      <div className="modal-wrapper box-shadow">
        <div
          className="confirm-modal-close cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          {SvgSelector('close')}
        </div>
        <div className="confirm-container">
          <div className="font-16-bold">
            {`Are you sure to delete object${multi ? 's' : ''} ?`}
          </div>
          <div className="confirm-delete-buttons">
            <Button
              color="gray_03"
              height={36}
              label="Cancel"
              onClick={() => setOpenModal(false)}
              width="50%"
              textColor="black"
            />
            <Button
              color="blue_strong"
              height={36}
              label="Delete"
              onClick={() => {
                deleteData(dataCard);
                setOpenModal(false);
              }}
              width="50%"
              textColor="white"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeleteModal;
