import { useContext, useState, forwardRef, useEffect } from 'react';
import { Emoji } from 'emoji-picker-react';
import '../../styles/floatMenu.css';
import { SvgSelector } from '../../helper/svg-selector';
import {
  floatMenuFolder,
  floatMenuStatus,
  floatMenuTogo,
} from '../../constants/arrays';
import { floatMenu, colors } from '../../constants';
import { DataContext } from '../../context/DataContext';
import FloatMenuAssignment from './FloatMenuAssignment';
// import FloatMenuSharing from './FloatMenuSharing';
import AnimationSlide from './AnimationSlide';
import Calendar from '../Calendar';
import { nodeColors } from '../../constants/colors';
import EmojiPicker from './EmojiPicker';
import CustomBoardEmoji from '../../assets/png/board-custom-icon.png';
import CustomProjectEmoji from '../../assets/png/project-custom-icon.png';
import StatusMenu from './StatusMenu';
import TagMenu from './TagMenu';
import ProOptionCard from '../ProAccount/ProOptionCard';
import ModalSharing from './ModalSharing';
import ShareTagMenu from './ShareTagMenu';

const FloatMenu = forwardRef((props, ref) => {
  const {
    node,
    delChild,
    emoji,
    color,
    setEmoji,
    status,
    schedule,
    project,
    isFolder,
    shareLayer,
    copyLink,
    setStepExpand,
    stepExpand,
    duplicate,
    MainNode,
    disableEdit,
    setFolder,
    showNotify,
    treeRef,
  } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [openedMenu, openMenu] = useState(-1);
  const {
    dispatch,
    updateTree,
    treeData,
    startNode,
    setStartNode,
    setRefresh,
    userAccess,
    // setInviteModal,
    shownAssignmentMenu,
    showAssignmentMenu,
    togoMode,
    boardId,
    setUnlockModal,
    isProUser,
  } = useContext(DataContext);
  const nodeOwner = treeData[node].owner_id || treeData[node].owner;
  const floatMenuItems =
    userAccess === 'WRITE'
      ? isFolder
        ? floatMenuFolder
        : togoMode
          ? floatMenuTogo
          : floatMenu
      : floatMenu.slice(0, 1);
  const colorName = color.toLowerCase();
  const tagName = treeData[boardId][colorName];
  const taskName =
    treeData[node]?.title && treeData[node].title[0]?.text.length > 0
      ? treeData[node].title[0].text
      : 'Untitled';
  const colorOrTag = tagName.toLowerCase() !== colorName ? tagName : null;
  const changeHandler = (type, i) => {
    updateTree(type, i);
    openMenu(-1);
  };

  const changeStatusNode = (item) => {
    if (item.value === status) {
      changeHandler('status', 'NO_STATUS');
    } else {
      changeHandler('status', item.value);
    }
  };

  const itemClick = (i) => {
    if (i.id !== 8 && openedMenu === i.id) {
      openMenu(-1);
      return;
    }
    switch (i.id) {
      case 0:
        dispatch({ type: 'boardToggle', payload: true });
        break;
      case 1:
        openMenu(1);
        break;
      case 2:
        if (isProUser) {
          disableEdit();
          openMenu(2);
        } else {
          openMenu('deadLinePro');
        }
        break;
      case 3:
        if (user && user.id === nodeOwner) {
          disableEdit();
          openMenu(3);
        } else {
          openMenu('shareColorMenu');
        }
        break;
      case 4:
        disableEdit();
        openMenu(4);
        break;
      case 5:
        if (!user) {
          setUnlockModal(true);
        } else {
          setStepExpand();
        }
        break;
      case 6:
        duplicate(node, treeData[node].parent);
        break;
      case 8:
        if (isProUser) {
          if (startNode === node) setStartNode(MainNode);
          else setStartNode(node);
          setRefresh((old) => old + 1);
        } else {
          openMenu('focusPro');
        }
        break;
      case 9:
        if (!user) {
          setUnlockModal(true);
        } else {
          disableEdit();
          openMenu(9);
        }
        break;
      case 10:
        setFolder(isFolder);
        break;
      case 11:
        if (!user) {
          setUnlockModal(true);
        } else {
          openMenu(11);
          disableEdit();
        }
        break;
      case 12:
        if (!user) {
          setUnlockModal(true);
        } else {
          dispatch({ type: 'showComment', payload: true });
          openMenu(-1);
        }
        break;
      case 13:
        if (user) {
          delChild();
        } else {
          setUnlockModal(true);
        }
        break;
      default:
        openMenu(-1);
        break;
    }
  };
  useEffect(() => {
    if (shownAssignmentMenu === node) {
      openMenu(9);
      disableEdit();
      showAssignmentMenu(-1);
    }
  }, []);

  const boardCustomEmoji = emoji === 'layer-board';
  const projectCustomEmoji = emoji === 'layer-project';
  return (
    <div
      id="float-menu"
      ref={ref}
      data-key="1"
      className={`${'popupMenuContainer'}${
        project ? ' popupMenuContainerProject' : ''
      }`}
    >
      <div className="popupMenu">
        {floatMenuItems.map((i) => (
          <button
            type="button"
            key={i.id}
            className={`popupMenuItem ${i.id !== 3 ? 'hover' : 'popupMenuItem-Color'} tooltip`}
            onClick={() => itemClick(i)}
          >
            {i.id === 1 ? (
              <div
                className="float-menu-status"
                style={{
                  backgroundColor: floatMenuStatus[status]
                    ? colors[floatMenuStatus[status]?.color]
                    : 'var(--white)',
                }}
              >
                {SvgSelector(floatMenuStatus[status]?.label || 'status')}
                <div className="tooltiptext font-10-med float-menu-status-tooltip">
                  Status
                </div>
              </div>
            ) : i.id === 2 ? (
              <div
                className="float-menu-calendar"
                style={{
                  backgroundColor: schedule
                    ? 'var(--green_light)'
                    : 'transparent',
                }}
              >
                <div
                  className="tooltiptext font-10-med"
                  style={{ width: `${!isProUser ? '85px' : '60px'}` }}
                >
                  DeadLine
                  {!isProUser && <span className="hover-text-pro">PRO</span>}
                </div>
                {SvgSelector(i.label)}
              </div>
            ) : i.id === 3 ? (
              <>
                <div
                  className={`float-menu-color font-14-med ${!colorOrTag ? 'float-menu-color-withoutTag' : ''}`}
                  style={{
                    backgroundColor: nodeColors[color],
                    whiteSpace: 'nowrap',
                  }}
                >
                  {colorOrTag}
                </div>
                <div className="tooltiptext font-10-med float-menu-status-tooltip">
                  Color
                </div>
              </>
            ) : i.id === 4 && emoji ? (
              <div className="float-menu-emoji">
                <Emoji
                  emojiUrl={
                    boardCustomEmoji
                      ? CustomBoardEmoji
                      : projectCustomEmoji
                        ? CustomProjectEmoji
                        : null
                  }
                  unified={
                    boardCustomEmoji || projectCustomEmoji ? '1f4d8' : emoji
                  }
                  // size="20"
                />
              </div>
            ) : i.id === 5 ? (
              !stepExpand ? (
                <>
                  <div className="tooltiptext font-10-med float-menu-status-tooltip">
                    Close
                  </div>
                  <div className="float-menu-collapse">
                    {SvgSelector(i.label)}
                  </div>
                </>
              ) : (
                <>
                  <div className="tooltiptext font-10-med float-menu-status-tooltip">
                    Open
                  </div>
                  <div className="float-menu-collapse">
                    {SvgSelector('hierarchyClose')}
                  </div>
                </>
              )
            ) : i.id === 8 ? (
              startNode === MainNode ? (
                <>
                  <div
                    className="tooltiptext font-10-med float-menu-status-tooltip"
                    style={{ width: `${!isProUser ? '70px' : '50px'}` }}
                  >
                    Focus
                    {!isProUser && <span className="hover-text-pro">PRO</span>}
                  </div>
                  <div className="float-menu-collapse">
                    {SvgSelector(i.label)}
                  </div>
                </>
              ) : (
                <>
                  <div className="tooltiptext font-10-med float-menu-status-tooltip">
                    Unfocus
                  </div>
                  <div className="float-menu-collapse">
                    {SvgSelector('eyeClose')}
                  </div>
                </>
              )
            ) : (
              <div
                className="float-menu-collapse"
                style={{
                  backgroundColor:
                    i.id === 5 && stepExpand ? 'var(--gray_06)' : 'transparent',
                }}
              >
                <div
                  className={`tooltiptext font-10-med ${
                    i.id !== 12 && 'float-menu-status-tooltip'
                  }`}
                >
                  {i.tooltip}
                </div>
                {SvgSelector(
                  i.label,
                  `${i.id === 12 ? 'commentIcon-level' : ''}`,
                )}
              </div>
            )}
          </button>
        ))}
      </div>
      <div className="float-menu-openMenu">
        <AnimationSlide
          show={openedMenu === 1}
          openMenu={openMenu}
          state="topToDown"
        >
          <StatusMenu handleClickItem={changeStatusNode} status={status} />
        </AnimationSlide>
        <AnimationSlide
          show={openedMenu === 2}
          openMenu={openMenu}
          state="topToDown"
        >
          <div className="float-menu-datePicker">
            <Calendar
              toggle={() => openMenu(-1)}
              node={node}
              changeHandler={changeHandler}
            />
          </div>
        </AnimationSlide>
        <AnimationSlide
          show={openedMenu === 3}
          openMenu={openMenu}
          state="topToDown"
        >
          <TagMenu task={node} directReq={false} />
        </AnimationSlide>
        <AnimationSlide
          show={openedMenu === 'shareColorMenu'}
          openMenu={openMenu}
          state="topToDown"
        >
          <ShareTagMenu task={node} showNotify={showNotify} />
        </AnimationSlide>
        <AnimationSlide
          openMenu={openMenu}
          show={openedMenu === 4}
          state="topToDown"
        >
          <div className="float-menu-emoji-container">
            <EmojiPicker selectEmoji={setEmoji} argument="emoji" />
          </div>
        </AnimationSlide>
        <AnimationSlide
          show={openedMenu === 9}
          openMenu={openMenu}
          state="topToDown"
        >
          <FloatMenuAssignment layer={treeData[node]} togoMode={togoMode} />
        </AnimationSlide>
        <AnimationSlide
          show={openedMenu === 11}
          openMenu={openMenu}
          state="topToDown"
        >
          <div className="float-menu-sharing-container">
            {/* <FloatMenuSharing
              node={node}
              shareLayer={shareLayer}
              setInviteModal={setInviteModal}
            /> */}
            <ModalSharing
              setOpenModal={openMenu}
              shareLayer={shareLayer}
              copyLink={copyLink}
              node={node}
              boardName={taskName}
              showNotify={showNotify}
              treeRef={treeRef}
            />
          </div>
        </AnimationSlide>
        <AnimationSlide
          openMenu={openMenu}
          show={openedMenu === 'deadLinePro'}
          state="topToDown"
        >
          <div className="upload-card-pro">
            <ProOptionCard
              closeMenu={openMenu}
              proText="Want to add deadline? Sign up for a tariff level right now!"
            />
          </div>
        </AnimationSlide>
        <AnimationSlide
          openMenu={openMenu}
          show={openedMenu === 'focusPro'}
          state="topToDown"
        >
          <div className="upload-card-pro">
            <ProOptionCard
              closeMenu={openMenu}
              proText="Want to focus the node? Sign up for a tariff level right now!"
            />
          </div>
        </AnimationSlide>
      </div>
    </div>
  );
});

FloatMenu.displayName = 'FloatMenu';
export default FloatMenu;
