import { useNavigate } from 'react-router-dom';
import Button from './Button';

function ModalUnlock({ setOpenMenu, type }) {
  const navigate = useNavigate(null);

  return (
    <div
      className={`${type === 'popUp' ? 'popUp-container box-shadow' : 'modal-container'}`}
      aria-hidden="true"
    >
      <div className="modal-unlock-container">
        <span className="font-16-bold">Want to unlock more features?</span>
        <span className="font-14-reg">
          Place for a motivating short text describing the benefits of Layer in
          two lines.
        </span>
        <div className="modal-unlock-button">
          <Button
            color="gray_04"
            height={36}
            label="Cancel"
            onClick={() => setOpenMenu(false)}
            textColor="black"
            width={206}
          />
          <Button
            color="blue_strong"
            height={36}
            label="Start now"
            onClick={() =>
              navigate(`${document.location.origin}/accounts/login/`)
            }
            textColor="white"
            width={206}
          />
        </div>
      </div>
    </div>
  );
}

export default ModalUnlock;
