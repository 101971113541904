import { useState } from 'react';
import Button from '../Elements/Button';
import ConfirmDeleteModal from '../Elements/ConfirmDeleteModal';
import AnimationSlide from '../Elements/AnimationSlide';

export default function MultiSelection({
  deleteMultiData,
  hardDeleteForEverData,
  selectedTab,
  showModalMoveIn,
}) {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const handleDelete = () => {
    if (selectedTab === 4) {
      hardDeleteForEverData();
      return;
    }
    deleteMultiData();
  };

  return (
    <div className="multi-selection-container">
      <Button
        labelClass="font-14-bold"
        label={selectedTab === 4 ? 'Permanently Delete ' : 'Delete'}
        textColor="black"
        color="orange_light"
        style={{ borderRadius: 6 }}
        width={selectedTab === 4 ? 160 : 80}
        onClick={() => setConfirmDeleteModal(true)}
      />
      {selectedTab === 1 && (
        <Button
          labelClass="font-14-bold"
          label="Move"
          textColor="black"
          color="orange_light"
          style={{ borderRadius: 6 }}
          width={80}
          onClick={() => showModalMoveIn(true)}
        />
      )}
      <AnimationSlide
        openMenu={setConfirmDeleteModal}
        show={confirmDeleteModal}
        state="topToDown"
      >
        <ConfirmDeleteModal
          setOpenModal={setConfirmDeleteModal}
          deleteData={handleDelete}
          multi
        />
      </AnimationSlide>
    </div>
  );
}
