/* eslint-disable import/prefer-default-export */
import { ReactComponent as PlusBtn } from '../assets/svg/button-plus.svg';
import { ReactComponent as Delete } from '../assets/svg/delete.svg';
import { ReactComponent as DeleteColorize } from '../assets/svg/delete-colorize.svg';
import { ReactComponent as Attach } from '../assets/svg/attach.svg';
import { ReactComponent as Bell } from '../assets/svg/bell.svg';
import { ReactComponent as Calendar } from '../assets/svg/calendar.svg';
import { ReactComponent as Hashtag } from '../assets/svg/hashtag.svg';
import { ReactComponent as Copy } from '../assets/svg/copy.svg';
import { ReactComponent as Eye } from '../assets/svg/eye.svg';
import { ReactComponent as EyeClose } from '../assets/svg/eye-close.svg';
import { ReactComponent as EyeColorize } from '../assets/svg/eye-colorize.svg';
import { ReactComponent as EyeCloseColorize } from '../assets/svg/eye-close-colorize.svg';
import { ReactComponent as Hierarchy } from '../assets/svg/hierarchy.svg';
import { ReactComponent as HierarchyClose } from '../assets/svg/hierarchy-close.svg';
import { ReactComponent as Spark } from '../assets/svg/spark.svg';
import { ReactComponent as Timer } from '../assets/svg/timer.svg';
import { ReactComponent as Upload } from '../assets/svg/upload.svg';
import { ReactComponent as Status } from '../assets/svg/status.svg';
import { ReactComponent as Menu } from '../assets/svg/menu.svg';
import { ReactComponent as Layers } from '../assets/svg/layers.svg';
import { ReactComponent as Layer } from '../assets/svg/layer.svg';
import { ReactComponent as Settings } from '../assets/svg/settings.svg';
import { ReactComponent as Alarm } from '../assets/svg/alarm.svg';
import { ReactComponent as ChevronDown } from '../assets/svg/chevron_down.svg';
import { ReactComponent as ChevronRight } from '../assets/svg/chevron_right.svg';
import { ReactComponent as Plus } from '../assets/svg/plus.svg';
import { ReactComponent as Delegate } from '../assets/svg/delegate.svg';
import { ReactComponent as Togo } from '../assets/svg/to_go.svg';
import { ReactComponent as ExpireToday } from '../assets/svg/expire_today.svg';
import { ReactComponent as AssignedToMe } from '../assets/svg/assigned_to_me.svg';
import { ReactComponent as Share } from '../assets/svg/share.svg';
import {
  ReactComponent as Backlog,
  ReactComponent as BackLog,
} from '../assets/svg/backlog.svg';
import { ReactComponent as Done } from '../assets/svg/done.svg';
import { ReactComponent as CheckMark } from '../assets/svg/check_mark.svg';
import { ReactComponent as Scale } from '../assets/svg/scale.svg';
import { ReactComponent as Scale2 } from '../assets/svg/scale-2.svg';
import { ReactComponent as Mail } from '../assets/svg/mail.svg';
import { ReactComponent as LinkImg } from '../assets/svg/link.svg';
import { ReactComponent as Pencil } from '../assets/svg/pencil.svg';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as CloseCircle } from '../assets/svg/close-circle.svg';
import { ReactComponent as Resize } from '../assets/svg/resize.svg';
import { ReactComponent as Document } from '../assets/svg/docs.svg';
import { ReactComponent as More } from '../assets/svg/more.svg';
import { ReactComponent as MoreVertical } from '../assets/svg/more-vertical.svg';
import { ReactComponent as TitleH1 } from '../assets/svg/title-h1.svg';
import { ReactComponent as TitleH2 } from '../assets/svg/title-h2.svg';
import { ReactComponent as TitleH3 } from '../assets/svg/title-h3.svg';
import { ReactComponent as TextSmall } from '../assets/svg/text-small.svg';
import { ReactComponent as Text } from '../assets/svg/text.svg';
import { ReactComponent as Bullet } from '../assets/svg/bullet.svg';
import { ReactComponent as Number } from '../assets/svg/number.svg';
import { ReactComponent as Toggle } from '../assets/svg/toggle.svg';
import { ReactComponent as Callout } from '../assets/svg/callout.svg';
import { ReactComponent as ImgLogo } from '../assets/svg/image.svg';
import { ReactComponent as Emoji } from '../assets/svg/emoji.svg';
import { ReactComponent as CheckList } from '../assets/svg/check-list.svg';
import { ReactComponent as Node } from '../assets/svg/node.svg';
import { ReactComponent as Person } from '../assets/svg/person.svg';
import { ReactComponent as File } from '../assets/svg/file.svg';
import { ReactComponent as PointList } from '../assets/svg/point-list.svg';
import { ReactComponent as NumberList } from '../assets/svg/number-list.svg';
import { ReactComponent as Save } from '../assets/svg/save.svg';
import { ReactComponent as Icon } from '../assets/svg/icon.svg';
import { ReactComponent as LayerIcon } from '../assets/svg/layer-icon.svg';
import { ReactComponent as MyBoard } from '../assets/svg/my-boards.svg';
import { ReactComponent as Earth } from '../assets/svg/earth.svg';
import { ReactComponent as Files } from '../assets/svg/files.svg';
import { ReactComponent as Search } from '../assets/svg/search.svg';
import { ReactComponent as Man } from '../assets/svg/man-working-from-home.svg';
import { ReactComponent as Project } from '../assets/svg/project.svg';
import { ReactComponent as Board } from '../assets/svg/board.svg';
import { ReactComponent as Folder } from '../assets/svg/folder.svg';
import { ReactComponent as NewFile } from '../assets/svg/file-new.svg';
import { ReactComponent as Team } from '../assets/svg/team.svg';
import { ReactComponent as Account } from '../assets/svg/account.svg';
import { ReactComponent as Refresh } from '../assets/svg/refresh.svg';
import { ReactComponent as Members } from '../assets/svg/members.svg';
import { ReactComponent as FolderOpen } from '../assets/svg/folder-open.svg';
import { ReactComponent as Logout } from '../assets/svg/logout.svg';
import { ReactComponent as Rename } from '../assets/svg/rename.svg';
import { ReactComponent as Favorite } from '../assets/svg/fav.svg';
import { ReactComponent as FavoriteFill } from '../assets/svg/fav-fill.svg';
import { ReactComponent as Spinner } from '../assets/svg/spinner.svg';
import { ReactComponent as SignIn } from '../assets/svg/logo-signin.svg';
import { ReactComponent as Register } from '../assets/svg/logo-register.svg';
import { ReactComponent as PasswordRecovery } from '../assets/svg/logo-recovery-password.svg';
import { ReactComponent as PasswordChange } from '../assets/svg/logo-change-password.svg';
import { ReactComponent as CheckBoxFill } from '../assets/svg/box-checked.svg';
import { ReactComponent as CheckBox } from '../assets/svg/box-un-checked.svg';
import { ReactComponent as CheckBoxHover } from '../assets/svg/un-checked-hover.svg';
import { ReactComponent as GridView } from '../assets/svg/grid-view.svg';
import { ReactComponent as Bold } from '../assets/svg/bold-01.svg';
import { ReactComponent as Italics } from '../assets/svg/italics-01.svg';
import { ReactComponent as Underline } from '../assets/svg/underline-01.svg';
import { ReactComponent as Strike } from '../assets/svg/type-strike-02.svg';
import { ReactComponent as AlignRight } from '../assets/svg/align-right.svg';
import { ReactComponent as AlignLeft } from '../assets/svg/align-left.svg';
import { ReactComponent as AlignJustify } from '../assets/svg/align-justify.svg';
import { ReactComponent as AlignCenter } from '../assets/svg/align-center.svg';
import { ReactComponent as ColorPicker } from '../assets/svg/color-picker.svg';
import { ReactComponent as RemoveFormat } from '../assets/svg/remove-format.svg';
import { ReactComponent as NoteBoard } from '../assets/svg/note-boards.svg';
import { ReactComponent as GridHandle } from '../assets/svg/grid.svg';
import { ReactComponent as Users } from '../assets/svg/users.svg';
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg';
import { ReactComponent as MultiColors } from '../assets/svg/multi-colors.svg';
import { ReactComponent as Palette } from '../assets/svg/palette.svg';
import { ReactComponent as Note } from '../assets/svg/note.svg';
import { ReactComponent as Like } from '../assets/svg/icon-like.svg';
import { ReactComponent as Liked } from '../assets/svg/icon-liked.svg';
import { ReactComponent as Comment } from '../assets/svg/comment.svg';
import { ReactComponent as MsgTyping } from '../assets/svg/message-typing.svg';
import { ReactComponent as Contrast } from '../assets/svg/contrast.svg';
import { ReactComponent as RadioBtn } from '../assets/svg/radio-button.svg';
import { ReactComponent as RadioBtnFill } from '../assets/svg/radio-button-fill.svg';
import { ReactComponent as LevelSelect } from '../assets/svg/level-select.svg';
import { ReactComponent as Levels } from '../assets/svg/levels.svg';
import { ReactComponent as ShortNodes } from '../assets/svg/short-node.svg';
import { ReactComponent as Export } from '../assets/svg/export.svg';
import { ReactComponent as Shuffle } from '../assets/svg/shuffle.svg';
import { ReactComponent as ArrowRotateLeft } from '../assets/svg/arrow-rotate-left.svg';
import { ReactComponent as Negative } from '../assets/svg/negative.svg';
import { ReactComponent as ChevronUp } from '../assets/svg/chevronUp.svg';
import { ReactComponent as BrokenCheck } from '../assets/svg/check-square-broken.svg';
import { ReactComponent as CalendarPlus } from '../assets/svg/calendar-plus.svg';
import { ReactComponent as UserProfile } from '../assets/svg/user-profile.svg';
import { ReactComponent as ClockForward } from '../assets/svg/clock-forward.svg';
import { ReactComponent as Temp } from '../assets/svg/map-01.svg';
import { ReactComponent as UploadBoard } from '../assets/svg/upload-03.svg';
import { ReactComponent as ChevronDoubleLeft } from '../assets/svg/chevron-double-left.svg';
import { ReactComponent as AddBoard } from '../assets/svg/addBoard.svg';
import { ReactComponent as AddProject } from '../assets/svg/addProject.svg';
import { ReactComponent as SelectFile } from '../assets/svg/selectFile.svg';
import { ReactComponent as HappyMan } from '../assets/svg/happyMan.svg';
import { ReactComponent as CheckBroken } from '../assets/svg/check-broken.svg';
import { ReactComponent as ArrowCurve } from '../assets/svg/arrow-curve-left-right.svg';
import { ReactComponent as ToggleArrow } from '../assets/svg/toggle-arrow-01.svg';
import { ReactComponent as ChevronLeft } from '../assets/svg/chevron-left.svg';
import { ReactComponent as AssignToMe } from '../assets/svg/assignToMe.svg';
import { ReactComponent as Expire } from '../assets/svg/expire.svg';
import { ReactComponent as LayerFav } from '../assets/svg/layer-fav.svg';
import { ReactComponent as SmallLoading } from '../assets/svg/smallLoading.svg';
import { ReactComponent as BackwardArrow } from '../assets/svg/backward-arrow.svg';
import { ReactComponent as Star } from '../assets/svg/star.svg';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { ReactComponent as Infinity } from '../assets/svg/infinity.svg';
import { ReactComponent as StarsFull } from '../assets/svg/stars-full.svg';
import { ReactComponent as Notification } from '../assets/svg/notification-bubble.svg';
import { ReactComponent as ProLogo } from '../assets/svg/pro-logo.svg';
import { ReactComponent as LayerFavPro } from '../assets/svg/layer-fav-pro.svg';

export const SvgSelector = (img, className) => {
  const blackStroke = 'icon-stroke-black';
  const blackFill = 'icon-black';

  const svgImages = {
    plusBtn: <PlusBtn className={className} />,
    delete: <Delete className={`${blackStroke} ${className}`} />,
    deleteColorize: <DeleteColorize className={className} />,
    attach: <Attach className={className} />,
    bell: <Bell className={className} />,
    calendar: <Calendar className={`${blackStroke} ${className}`} />,
    copy: <Copy className={`${blackStroke} ${className}`} />,
    eye: <Eye className={`${blackFill} ${className}`} />,
    eyeColorize: <EyeColorize className={className} />,
    eyeClose: <EyeClose className={`${blackFill} ${className}`} />,
    eyeCloseColorize: <EyeCloseColorize className={className} />,
    hierarchy: <Hierarchy className={`${blackStroke} ${className}`} />,
    hierarchyClose: <HierarchyClose className={`${blackFill} ${className}`} />,
    spark: <Spark className={className} />,
    timer: <Timer className={className} />,
    upload: <Upload className={`${blackStroke} ${className}`} />,
    status: <Status className={`${blackStroke} ${className}`} />,
    menu: <Menu className={className} />,
    layers: <Layers className={`${blackStroke} ${className}`} />,
    layer: <Layer className={`${blackFill} ${className}`} />,
    settings: <Settings className={`${blackStroke} ${className}`} />,
    alarm: <Alarm className={className} />,
    chevronDown: <ChevronDown className={`${blackStroke} ${className}`} />,
    chevronRight: <ChevronRight className={`${blackFill} ${className}`} />,
    chevronUp: <ChevronUp className={`${blackFill} ${className}`} />,
    delegate: <Delegate className={`${blackStroke} ${className}`} />,
    togo: <Togo className={`${blackFill} ${className}`} />,
    plus: <Plus className={`${blackStroke} ${className}`} />,
    done: <Done className={`${blackStroke} ${className}`} />,
    expireToday: <ExpireToday className={`${blackFill} ${className}`} />,
    assignedToMe: <AssignedToMe className={`${blackFill} ${className}`} />,
    backlog: <Backlog className={`${blackFill} ${className}`} />,
    checkMark: <CheckMark className={`${blackStroke} ${className}`} />,
    scale: <Scale className={`${blackStroke} ${className}`} />,
    scale2: <Scale2 className={`${blackFill} ${className}`} />,
    mail: <Mail className={`${blackStroke} ${className}`} />,
    link: <LinkImg className={`${blackStroke} ${className}`} />,
    pencil: <Pencil className={`${blackStroke} ${className}`} />,
    resize: <Resize className={className} />,
    close: <Close className={`${blackStroke} ${className}`} />,
    closeCircle: <CloseCircle className={`${className}`} />,
    document: <Document className={className} />,
    more: <More className={className} />,
    moreVertical: <MoreVertical className={`${blackFill} ${className}`} />,
    header1: <TitleH1 className={`${blackStroke} ${className}`} />,
    header2: <TitleH2 className={`${blackStroke} ${className}`} />,
    header3: <TitleH3 className={`${blackFill} ${className}`} />,
    typePlus: <TextSmall className={`${blackStroke} ${className}`} />,
    bullet: <Bullet className={`${className}`} />,
    number: <Number className={`${className}`} />,
    toggle: <Toggle className={`${className}`} />,
    callout: <Callout className={`${className}`} />,
    image: <ImgLogo className={`${className}`} />,
    emoji: <Emoji className={`${className}`} />,
    checkList: <CheckList className={`${className}`} />,
    node: <Node className={`${className}`} />,
    person: <Person className={`${className}`} />,
    text: <Text className={`${blackFill} ${className}`} />,
    file: <File className={className} />,
    pointList: <PointList className={`${blackFill} ${className}`} />,
    numberList: <NumberList className={`${blackStroke} ${className}`} />,
    save: <Save className={`${blackStroke} ${className}`} />,
    icon: <Icon className={className} />,
    layerIcon: <LayerIcon className={className} />,
    myBoards: <MyBoard className={`${blackFill} ${className}`} />,
    earth: <Earth className={`${blackFill} ${className}`} />,
    files: <Files className={`${blackFill} ${className}`} />,
    search: <Search className={`${blackFill} ${className}`} />,
    man: <Man className={className} />,
    project: <Project className={className} />,
    board: <Board className={className} />,
    folder: <Folder className={`${blackFill} ${className}`} />,
    newFile: <NewFile className={`${blackFill} ${className}`} />,
    team: <Team className={className} />,
    account: <Account className={`${blackFill} ${className}`} />,
    refresh: <Refresh className={`${blackFill} ${className}`} />,
    members: <Members className={`${blackFill} ${className}`} />,
    folderOpen: <FolderOpen className={`${blackFill} ${className}`} />,
    logout: <Logout className={`${blackFill} ${className}`} />,
    rename: <Rename className={`${blackFill} ${className}`} />,
    fav: <Favorite className={className} />,
    favFill: <FavoriteFill className={className} />,
    spinner: <Spinner className={className} />,
    signIn: <SignIn className={className} />,
    register: <Register className={className} />,
    recovery: <PasswordRecovery className={className} />,
    changePassword: <PasswordChange className={className} />,
    checkBoxFill: <CheckBoxFill className={className} />,
    checkBox: <CheckBox className={className} />,
    checkBoxHover: <CheckBoxHover className={className} />,
    gridView: <GridView className={`${blackFill} ${className}`} />,
    lineageView: <PointList className={`${blackFill} ${className}`} />,
    bold: <Bold className={`${blackFill} ${className}`} />,
    italics: <Italics className={`${blackFill} ${className}`} />,
    underline: <Underline className={`${blackFill} ${className}`} />,
    strike: <Strike className={`${blackFill} ${className}`} />,
    alignRight: <AlignRight className={`${blackFill} ${className}`} />,
    alignLeft: <AlignLeft className={`${blackFill} ${className}`} />,
    alignJustify: <AlignJustify className={`${blackFill} ${className}`} />,
    alignCenter: <AlignCenter className={`${blackFill} ${className}`} />,
    colorPicker: <ColorPicker className={`${blackFill} ${className}`} />,
    removeFormat: <RemoveFormat className={`${blackStroke} ${className}`} />,
    noteBoard: <NoteBoard className={className} />,
    gridHandle: <GridHandle className={className} />,
    users: <Users className={`${blackFill} ${className}`} />,
    arrowLeft: <ArrowLeft className={`${blackFill} ${className}`} />,
    multiColors: <MultiColors className={className} />,
    palette: <Palette className={`${blackFill} ${className}`} />,
    note: <Note className={className} />,
    like: <Like className={className} />,
    liked: <Liked className={className} />,
    comment: <Comment className={`${blackFill} ${className}`} />,
    msgTyping: <MsgTyping className={`${blackFill} ${className}`} />,
    contrast: <Contrast className={`${blackFill} ${className}`} />,
    radioBtn: <RadioBtn className={className} />,
    radioBtnFill: <RadioBtnFill className={className} />,
    levelSelect: <LevelSelect className={`${blackFill} ${className}`} />,
    levels: <Levels className={`${blackFill} ${className}`} />,
    shortNodes: <ShortNodes className={`${blackFill} ${className}`} />,
    export: <Export className={`${blackFill} ${className}`} />,
    shuffle: <Shuffle className={`${blackFill} ${className}`} />,
    arrowRotateLeft: (
      <ArrowRotateLeft className={`${blackFill} ${className}`} />
    ),
    negative: <Negative className={`${blackFill} ${className}`} />,
    brokenCheck: <BrokenCheck className={`${blackFill} ${className}`} />,
    calendarPlus: <CalendarPlus className={`${blackFill} ${className}`} />,
    userProfile: <UserProfile className={`${blackFill} ${className}`} />,
    clockForward: <ClockForward className={`${blackFill} ${className}`} />,
    temp: <Temp className={`${blackFill} ${className}`} />,
    uploadBoard: <UploadBoard className={`${blackFill} ${className}`} />,
    chevronDoubleLeft: (
      <ChevronDoubleLeft className={`${blackFill} ${className}`} />
    ),
    addBoard: <AddBoard className={`${blackFill} ${className}`} />,
    addProject: <AddProject className={`${blackFill} ${className}`} />,
    selectFile: <SelectFile className={`${blackFill} ${className}`} />,
    happyMan: <HappyMan className={`${blackFill} ${className}`} />,
    checkBroken: <CheckBroken className={`${blackFill} ${className}`} />,
    arrowCurve: <ArrowCurve className={`${blackFill} ${className}`} />,
    toggleArrow: <ToggleArrow className={`${blackFill} ${className}`} />,
    chevronLeft: <ChevronLeft className={`${blackFill} ${className}`} />,
    hashtag: <Hashtag className={`${blackFill} ${className}`} />,
    assignToMe: <AssignToMe className={`${blackFill} ${className}`} />,
    expire: <Expire className={`${blackFill} ${className}`} />,
    backLog: <BackLog className={className} />,
    layerFav: <LayerFav />,
    smallLoading: <SmallLoading className={`${blackFill} ${className}`} />,
    backwardArrow: <BackwardArrow className={className} />,
    forwardArrow: <BackwardArrow className={`flip ${className}`} />,
    share: <Share className={`${className}`} />,
    star: <Star className={`${blackFill} ${className}`} />,
    logo: <Logo className={`${blackFill} ${className}`} />,
    infinity: <Infinity className={`${blackFill} ${className}`} />,
    starsFull: <StarsFull className={`${blackFill} ${className}`} />,
    notification: <Notification className={` ${blackFill} ${className}`} />,
    proLogo: <ProLogo className={`${className}`} />,
    layerFavPro: <LayerFavPro className={`${className}`} />,
  };

  return svgImages[img];
};
