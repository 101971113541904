import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/proAccount.scss';
import { DataContext } from '../../context/DataContext';
import StarterTariff from './StarterTariff';
import ProTariff from './ProTariff';
import ContactUs from './ContactUs';
import dataServices from '../../services/dataServices';
import Loading from '../Elements/Loading';

function Tariff() {
  const { boardId } = useContext(DataContext);
  const [plansData, setPlansData] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    dataServices.getAllPlans().then((res) => {
      setIsLoading(false);
      setPlansData(res.data);
    });
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="tariff-container">
      <div
        className="hover cursor-pointer tariff-close-container"
        onClick={() => navigate(`/${boardId}`)}
      >
        {SvgSelector('close')}
      </div>
      <div className="tariff-offer-container">
        <div className="tariff-title">
          {SvgSelector('logo', 'tariff-logo')}
          <span className="font-h1">Pricing plans for you and your team</span>
        </div>
        <div className="tariff-offer">
          <StarterTariff />
          {plansData.map((plans) => (
            <ProTariff data={plans} setIsLoading={setIsLoading} />
          ))}
          <ContactUs />
        </div>
        <div className="tariff-act-container">
          {SvgSelector('happyMan')}
          <span className="font-h2">Wait for the right moment, act!</span>
        </div>
      </div>
    </div>
  );
}

export default Tariff;
