import { useContext, useEffect, useState } from 'react';
import { SvgSelector } from '../../helper/svg-selector';
import Avatar from './Avatar';
import { objSubtraction, showUserName } from '../../helper/functions';
import { DataContext } from '../../context/DataContext';
import dataServices from '../../services/dataServices';
import AnimationSlide from './AnimationSlide';
import ProOptionCard from '../ProAccount/ProOptionCard';

export default function FloatMenuAssignment({ layer, togoMode }) {
  const { assignUserToTask, unAssignUserToTask, boardId, isProUser } =
    useContext(DataContext);
  const [searchContent, setSearchContent] = useState('');
  const [assignedUsers, setAssignedUsers] = useState(layer?.assignments || []);
  const [boardUsers, setBoardUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [openProMenu, setOpenProMenu] = useState(false);

  const getBoardMembers = () => {
    dataServices.getMemberships(boardId).then((res) => {
      const members = objSubtraction(res.data, assignedUsers);
      setBoardUsers(members);
      setUsers(members);
    });
  };

  useEffect(() => {
    if (!searchContent?.length > 0) {
      const members = objSubtraction(boardUsers, assignedUsers);
      setUsers(members);
      return;
    }
    dataServices
      .getLayerUsers({
        email__icontains: searchContent?.toLowerCase(),
      })
      .then((res) => {
        if (searchContent.length > 0) {
          setUsers(res.data.slice(0, 2) || []);
        } else {
          const members = objSubtraction(boardUsers, assignedUsers);
          setUsers(members);
        }
      });
  }, [searchContent]);

  const assignTask = (user) => {
    const duplicateUser = assignedUsers.filter((u) => u.id === user.id);
    if (duplicateUser.length > 0) return;
    const filteredBoardUsers = boardUsers.filter((u) => u.id !== user.id);
    setAssignedUsers((old) => [...old, user]);
    setBoardUsers(filteredBoardUsers);
    setUsers(filteredBoardUsers);
    assignUserToTask([layer.id], user);
  };

  const checkUserProToAssign = (user) => {
    const assignedNumbers = assignedUsers.length;
    if (isProUser || assignedNumbers < 5) {
      assignTask(user);
    } else if (!isProUser && assignedNumbers >= 5) {
      setOpenProMenu(true);
    }
  };
  const unAssignTask = (user) => {
    const filteredAssignedUsers = assignedUsers.filter((u) => u.id !== user.id);
    setAssignedUsers(filteredAssignedUsers);
    setBoardUsers((old) => [...old, user]);
    setUsers((old) => [...old, user]);
    unAssignUserToTask(layer, user);
  };

  useEffect(() => {
    getBoardMembers();
  }, []);

  return (
    <div
      className={`popupMenuAssignment ${
        togoMode ? 'popupMenuAssignmentToGo' : ''
      }`}
    >
      <div className="popupMenuAssignmentAssignedMembers">
        {assignedUsers?.map((user) => (
          <div key={user.id} className="popupMenuAssignmentAssignedMember">
            <Avatar
              size={22}
              name={user.email}
              font="font-12-med"
              style={{ marginRight: 5 }}
              isRounded
            />
            <div className="modal-move-in-item-text font-12-bold">
              {showUserName(user)}
            </div>
            <div onClick={() => unAssignTask(user)}>
              {SvgSelector(
                'close',
                'popupMenuAssignmentCloseIcon cursor-pointer',
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="popupMenuAssignmentSearchBox">
          {SvgSelector('search', 'popupMenuAssignmentSearchBoxIcon')}
          <input
            className="font-14-med float-menu-search-member"
            value={searchContent}
            onChange={(e) => setSearchContent(e.target.value)}
            placeholder="Search names"
          />
        </div>
      </div>
      <div className="float-menu-assign-container">
        <div className="font-14-med float-menu-assign-title">
          {searchContent.length > 0 ? 'Search Result' : 'Recommended Users'}
        </div>
        {users?.map((user) => (
          <div
            key={user.id}
            className="popupMenuAssignmentMember flex-row cursor-pointer"
          >
            <Avatar
              size={22}
              name={user.email}
              font="font-12-med"
              style={{ marginRight: 5 }}
              isRounded
            />
            <div
              className="modal-move-in-item-text font-14-bold"
              onClick={() => checkUserProToAssign(user)}
            >
              {showUserName(user)}
            </div>
          </div>
        ))}
      </div>
      <AnimationSlide
        show={openProMenu}
        openMenu={setOpenProMenu}
        state="rightToLeft"
      >
        <div className="float-menu-assign-pro-card">
          <ProOptionCard
            closeMenu={setOpenProMenu}
            proText="Only 5 members.Use PRO without restrictions!"
          />
        </div>
      </AnimationSlide>
    </div>
  );
}
