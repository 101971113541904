import { useState, useEffect, useContext } from 'react';
import '../../styles/modalSharing.scss';
import Avatar from './Avatar';
import { SvgSelector } from '../../helper/svg-selector';
import Button from './Button';
import { accessList } from '../../constants/arrays';
import dataServices from '../../services/dataServices';
import AnimationSlide from './AnimationSlide';
import { DataContext } from '../../context/DataContext';
import ShareMemberCard from './ShareMememberCard';

function ModalSharingInvite({ node, showNotify, setOpenModal }) {
  const { treeData } = useContext(DataContext);
  const [focusInvite, setFocusInvite] = useState(false);
  const [accessMenu, setAccessMenu] = useState(false);
  const [email, setEmail] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [info, setInfo] = useState();
  const [verifiedEmail, setVerifiedEmail] = useState([]);
  const [shareAccess, setShareAccess] = useState({
    name: 'Can view',
    value: 'READ',
  });
  const [checkLoginUser, setCheckLoginUser] = useState(false);
  const member = treeData[node].members || [];

  const onConfirm = () => {
    if (isLoading || (!!info && !!email)) return;
    setLoading(true);
    const data = {
      changes: verifiedEmail,
    };
    dataServices.multiInvite(node, data).then(() => {
      setLoading(false);
      setOpenModal(false);
      if (!checkLoginUser) {
        verifiedEmail.map((i) => {
          member.push(i);
          return null;
        });
      }
      showNotify({
        isLoading: false,
        text: 'Invitation sent',
        actionText: 'Okay',
        action: null,
      });
    });
  };

  useEffect(() => {
    if (!email?.length > 0) {
      setInfo(null);
      return;
    }
    dataServices
      .getLayerUsers({
        email__icontains: email?.toLowerCase(),
      })
      .then((res) => {
        if (!res?.errors?.length > 0) {
          setInfo(res.data[0]);
          if (res.data.length === 0) {
            const unLoginUser = {
              email: email?.toLowerCase(),
              privilege: shareAccess.value,
            };
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailPattern.test(email?.toLowerCase())) {
              setCheckLoginUser(true);
              setVerifiedEmail([unLoginUser]);
            }
          }
        }
      });
  }, [email]);

  console.log(verifiedEmail, 'verifiedEmail');

  const verifyEmail = () => {
    const showEmail = verifiedEmail.find((i) => i.email === info.email);
    if (showEmail) return;
    setEmail('');
    setVerifiedEmail([
      ...verifiedEmail,
      { email: info.email, privilege: shareAccess.value },
    ]);
  };

  const deleteVerifiedEmail = (delItem) => {
    const result = verifiedEmail.filter((i) => i.email !== delItem.email);
    setVerifiedEmail(result);
  };

  const changeAccess = (item) => {
    const lastAccess = [...verifiedEmail];
    lastAccess.map((i) => {
      // eslint-disable-next-line
      i.privilege = item.value;
      return null;
    });
    setVerifiedEmail(lastAccess);
    setShareAccess(item);
  };

  return (
    <div className="invite-container-modal">
      <div className="padding-md modal-sharing-share-container">
        <div className="font-14-med">Add people to collaborate</div>
        <div className="modal-sharing-share-link">
          <div className="modal-sharing-invite-container">
            <div
              className={`modal-sharing-invite-member ${focusInvite ? 'modal-sharing-invite-input-active' : ''}`}
            >
              <div>
                <div className="invite-verify-email-container">
                  {verifiedEmail.map((i) => (
                    <span className="modal-sharing-invite-verify-email">
                      <div className="flex-row">
                        <Avatar size={16} name={i.email} />
                        <span className="font-12-bold mx-5">{i.email}</span>
                      </div>
                      <div
                        onClick={() => deleteVerifiedEmail(i)}
                        className="cursor-pointer"
                      >
                        {SvgSelector('close', 'invite-verify-email-icon')}
                      </div>
                    </span>
                  ))}
                </div>
                <input
                  className="modal-sharing-invite-input"
                  type="text"
                  onFocus={() => setFocusInvite(true)}
                  onBlur={() => setFocusInvite(false)}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                {verifiedEmail.length !== 0 && (
                  <div
                    className="access-select font-14-med"
                    onClick={() => setAccessMenu(true)}
                    style={{ justifyContent: 'flex-start', marginLeft: '5px' }}
                  >
                    <span className="copy-link-access-text">
                      {shareAccess.name}
                    </span>
                    <div className="copy-link-icon-link align-center">
                      {SvgSelector('chevronDown')}
                    </div>
                    <AnimationSlide
                      show={accessMenu}
                      openMenu={setAccessMenu}
                      state="topToDown"
                    >
                      <div className="copy-link-change-access-invite cursor-pointer">
                        {accessList.map((i) => (
                          <div
                            className="copy-link-access-item"
                            onClick={() =>
                              changeAccess({ name: i.text, value: i.value })
                            }
                          >
                            <div className="copy-link-access-icon-container">
                              <div>
                                {SvgSelector(
                                  `${i.icon}`,
                                  'copy-link-access-icon',
                                )}
                              </div>
                              <span>{i.text}</span>
                            </div>
                            {i.value === shareAccess.value && (
                              <div>{SvgSelector('checkMark')}</div>
                            )}
                          </div>
                        ))}
                      </div>
                    </AnimationSlide>
                  </div>
                )}
              </div>
            </div>
            <Button
              color="blue_strong"
              height={36}
              width={65}
              label="Invite"
              textColor="white"
              labelClass="font-14-bold"
              onClick={() => onConfirm()}
            />
          </div>
          {info && (
            <span
              className="font-12-med modal-sharing-invite-email cursor-pointer"
              onClick={verifyEmail}
            >
              {info.email}
            </span>
          )}
        </div>
        <div className="modal-sharing-invite-list-container">
          <span className="font-14-med">Manage access</span>
          {member.map((i) => (
            <ShareMemberCard
              email={i.email}
              privilege={i.privilege}
              node={node}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ModalSharingInvite;
