import { v4 as uuidv4 } from 'uuid';
import '../../styles/taskBoard.css';
import { Emoji } from 'emoji-picker-react';
import { useEffect, useState, useContext } from 'react';
import { colors } from '../../constants';
import {
  boardMenu,
  floatMenuStatus,
  sendOptions,
} from '../../constants/arrays';
import { DataContext } from '../../context/DataContext';
import { SvgSelector } from '../../helper/svg-selector';
import TaskNote from './TaskNote';
import FloatMenuSharing from '../Elements/FloatMenuSharing';
import AnimationSlide from '../Elements/AnimationSlide';
import Calendar from '../Calendar';
import { nodeColors } from '../../constants/colors';
import { getAncestors } from '../../helper/functions';
import FloatMenuAssignment from '../Elements/FloatMenuAssignment';
import ModalExport from '../Elements/ModalExport';
import dataServices from '../../services/dataServices';
import { blockHeadingLevels, blockTypes } from '../../constants/objects';
import TagMenu from '../Elements/TagMenu';
import EmojiPicker from '../Elements/EmojiPicker';
import CustomBoardEmoji from '../../assets/png/board-custom-icon.png';
import CustomProjectEmoji from '../../assets/png/project-custom-icon.png';
import ProOptionCard from '../ProAccount/ProOptionCard';

let data;
let timer;
let defaultId = null;
let reqCounter = 0;
function TaskBoard({ deleteNode, shareLayer, treeRef, selectedNode }) {
  const {
    dispatch,
    treeData,
    boardId,
    userAccess,
    setInviteModal,
    blockParent,
    setBlockParent,
    updateTree,
    isProUser,
  } = useContext(DataContext);
  const labels = getAncestors(treeData, blockParent);
  if (blockParent && blockParent !== -1) data = treeData[blockParent];
  const [fullScr, setFullScr] = useState(false);
  const [title, setTitle] = useState('');
  const [openedMenu, openMenu] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [note, setNote] = useState(undefined);
  const [isFolder, setIsFolder] = useState(data?.layer_type === 'FOLDER');
  const changeHandler = (type, i) => {
    updateTree(type, i, treeData);
    openMenu(-1);
  };

  function formatDate(string) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
    };
    return new Date(string).toLocaleDateString([], options);
  }

  const itemClick = (item) => {
    if (openedMenu === item.id) {
      openMenu(-1);
      return;
    }
    switch (item.id) {
      case 0:
        openMenu(0);
        break;
      case 1:
        if (isProUser) {
          openMenu(1);
        } else {
          openMenu(12);
        }
        break;
      case 2:
        openMenu(2);
        break;
      case 3:
        openMenu(3);
        break;
      case 4:
        changeHandler('layer_type', isFolder ? 'TASK' : 'FOLDER');
        setIsFolder(!isFolder);
        break;
      case 5:
        openMenu(5);
        break;
      case 6:
        openMenu(6);
        break;
      case 7:
        dispatch({ type: 'boardToggle', payload: false });
        deleteNode(data?.id);
        break;
      case 8:
        setFullScr((old) => !old);
        break;
      case 9:
        dispatch({ type: 'boardToggle', payload: false });
        break;

      default:
        break;
    }
  };

  const getTask = () => {
    reqCounter += 1;
    dataServices
      .getLayerById(blockParent)
      .then((res) => getNotes(res.data.order))
      .catch((err) => {
        if (err?.response?.status === 403)
          setTimeout(() => {
            if (reqCounter > 5) reqCounter = 0;
            else getTask();
          }, 1000);
      });
  };

  const getDefaultBlock = () => {
    defaultId = uuidv4();

    return [
      {
        type: 'paragraph',
        id: defaultId,
      },
    ];
  };

  const getNotes = (nodeOrder) => {
    reqCounter = 0;
    dataServices
      .getLayerDescendants(blockParent, {
        layer_type__in: ['BLOCK'].toString(),
      })
      .then((res) => {
        if (nodeOrder.length) {
          const newData = [];
          res.data.map((i) => {
            treeData[i.id] = { ...i, blockOrder: i.order };
            return treeData[i.id];
          });
          nodeOrder.forEach((item) => {
            const b = res.data.filter((i) => i.id === item)[0];
            if (b) {
              newData.push({
                id: b.id,
                type: blockTypes[b.block_type],
                content: b.title,
                props: {
                  type: { ...b, blockOrder: b.order },
                  level: blockHeadingLevels(b.block_type),
                },
              });
            }
          });
          setNote(newData.length > 0 ? newData : getDefaultBlock);
        } else {
          setNote(getDefaultBlock);
        }
      });
  };

  const updateServer = (val) => {
    treeData[blockParent].title = [{ text: val, type: 'text', style: {} }];
    dataServices.addBlock({
      id: blockParent,
      title: [{ text: val, type: 'text', style: {} }],
    });
  };

  const debounceAction = (val) => {
    if (userAccess !== 'WRITE') return;
    setTitle(val);
    clearTimeout(timer);
    timer = setTimeout(() => updateServer(val), 500);
  };

  useEffect(() => {
    setNote(null);
    getTask();
    setTitle(data?.title[0]?.text || '');
  }, [blockParent]);

  const forwardHandler = () => {
    const parent = treeData[blockParent]?.parent;
    const parentType = treeData[parent]?.layer_type;
    const ParentOfParent = treeData[parent]?.parent;
    if (parentType === 'BLOCK') setBlockParent(ParentOfParent);
  };

  const setEmoji = (_, emj) => {
    updateTree('emoji', emj);
  };

  const backToFirstNode = () => {
    setBlockParent(selectedNode);
  };

  const firstChildrenNode = Object.values(treeData).find(
    (i) => i.parent === selectedNode && i.block_type === 'NODE',
  );

  const navigation = labels?.filter((i) => i.title.length !== 0) || [];
  const firstNode = navigation.find(
    (i) => i.node === firstChildrenNode?.parent,
  );
  const firstNodeIdx = navigation.indexOf(firstNode);
  const navigationList = navigation?.slice(firstNodeIdx);

  return (
    <div
      className="board-container task-board-container"
      aria-hidden="true"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          dispatch({ type: 'boardToggle', payload: false });
        }
      }}
    >
      <div className={`wrapper-text-editor ${fullScr ? 'fullScr' : ''}`}>
        <div className="board-upper">
          <div className="flex-row" style={{ height: '55px' }}>
            <div onClick={() => forwardHandler()}>
              {SvgSelector('backwardArrow', 'navigationButtons')}
            </div>
            {!fullScr &&
              navigationList.map((i, index) => {
                const boardCustomEmoji = i.emoji === 'layer-board';
                const projectCustomEmoji = i.emoji === 'layer-project';
                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className="main-menu-title-separator-nav"
                    style={{
                      opacity: labels.length - 1 === index ? 1 : 0.5,
                    }}
                  >
                    <div
                      className="font-14-med main-menu-title-icon"
                      style={{ marginLeft: '2px' }}
                    >
                      {navigationList.length > 3 && i.node !== boardId ? (
                        <div>
                          {index === 0 &&
                            index !== navigationList.length - 1 && (
                              <div className="main-menu-navigator">
                                {i.emoji && (
                                  <p>
                                    <Emoji
                                      emojiUrl={
                                        boardCustomEmoji
                                          ? CustomBoardEmoji
                                          : projectCustomEmoji
                                            ? CustomProjectEmoji
                                            : null
                                      }
                                      unified={
                                        boardCustomEmoji || projectCustomEmoji
                                          ? '1f4d8'
                                          : i.emoji
                                      }
                                      size="20"
                                    />
                                  </p>
                                )}
                                <div
                                  className="cursor-pointer"
                                  onClick={() => backToFirstNode()}
                                >
                                  {i.title[0].text}
                                </div>
                                / ... /
                              </div>
                            )}
                          {index === navigationList.length - 2 &&
                            index !== 0 && (
                              <div
                                onClick={() => setBlockParent(i.node)}
                                className="cursor-pointer"
                              >
                                {i.title[0].text} /
                              </div>
                            )}

                          {index === navigationList.length - 1 &&
                            index !== 0 && <div>{i.title[0].text}</div>}
                        </div>
                      ) : (
                        i.node !== boardId && (
                          <div
                            className="main-menu-title main-menu-last-title cursor-pointer"
                            onClick={() =>
                              index !== navigationList.length - 1 && index !== 0
                                ? forwardHandler()
                                : index === 0
                                  ? backToFirstNode()
                                  : null
                            }
                          >
                            {i.emoji && (
                              <p>
                                <Emoji
                                  emojiUrl={
                                    boardCustomEmoji
                                      ? CustomBoardEmoji
                                      : projectCustomEmoji
                                        ? CustomProjectEmoji
                                        : null
                                  }
                                  unified={
                                    boardCustomEmoji || projectCustomEmoji
                                      ? '1f4d8'
                                      : i.emoji
                                  }
                                  size="20"
                                />
                              </p>
                            )}
                            {`${index !== 0 ? '/ ' : ''} ${i.title[0].text}`}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                );
              })}
            {navigation?.map((i, index) => (
              <div key={i.id} className="inline-center">
                {fullScr && (
                  <div
                    className="inline-center"
                    style={{ opacity: labels.length === index + 1 ? 1 : 0.5 }}
                  >
                    {i.emoji && (
                      <p>
                        <Emoji unified={i.emoji} size="20" />
                      </p>
                    )}
                    <div className="font-14-med task-board-label">
                      {i.title[0] ? i.title[0].text : ''}
                    </div>
                  </div>
                )}
                {/* {!fullScr && index === 1 && index !== labels.length - 1 && (
                  <div className="task-board-load">...</div>
                )} */}
                {labels.length !== index + 1 && fullScr && (
                  <div className="task-board-name">/</div>
                )}
              </div>
            ))}
          </div>
          <div>
            <div className="inline-center">
              {userAccess === 'WRITE' && (
                <div className="board-menu">
                  {boardMenu.slice(0, 7).map((i) => (
                    <div
                      aria-hidden="true"
                      key={i.id}
                      className={`menu-item hover ${
                        (i.id === 0 || i.id === 1) && 'wl'
                      }`}
                      onClick={() => itemClick(i)}
                    >
                      {i.id === 0 ? (
                        <div
                          className="task-board-check-status"
                          style={{
                            backgroundColor:
                              colors[
                                floatMenuStatus[data?.status || 'NO_STATUS']
                                  ?.color
                              ],
                          }}
                        >
                          {SvgSelector(
                            floatMenuStatus[data?.status]?.label || 'status',
                          )}
                          {SvgSelector('chevronDown')}
                        </div>
                      ) : i.id === 1 ? (
                        <div
                          className="task-board-check-date"
                          style={{
                            backgroundColor: data?.schedule
                              ? 'var(--green_light)'
                              : 'transparent',
                          }}
                        >
                          {SvgSelector(i.label)}
                          {SvgSelector('chevronDown')}
                        </div>
                      ) : i.id === 2 ? (
                        <div
                          className="task-board-check-color"
                          style={{
                            backgroundColor: nodeColors[data?.color],
                          }}
                        />
                      ) : (
                        SvgSelector(i.label)
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className="task-board-menu-separator" />
              <div className="board-menu">
                {boardMenu.slice(-2).map((i) => (
                  <div
                    aria-hidden="true"
                    key={i.id}
                    className="menu-item hover"
                    onClick={() => itemClick(i)}
                  >
                    {i.id === 0 ? (
                      <div
                        className="task-board-status"
                        style={{
                          backgroundColor: `var(--${
                            floatMenuStatus[data?.status]?.color || 0
                          })`,
                        }}
                      >
                        {SvgSelector(floatMenuStatus[data?.status]?.label)}
                      </div>
                    ) : i.id === 1 && data?.schedule ? (
                      <div className="task-board-date">
                        {SvgSelector(i.label)}
                      </div>
                    ) : i.id === 2 ? (
                      <div
                        className="task-board-color"
                        style={{
                          backgroundColor: data?.color,
                        }}
                      />
                    ) : (
                      SvgSelector(i.label, 'icon-stroke-black')
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <AnimationSlide
                show={openedMenu === 0}
                openMenu={openMenu}
                state="topToDown"
              >
                <div className="popupMenuColumns">
                  {Object.values(floatMenuStatus).map((i) => (
                    <div
                      aria-hidden="true"
                      key={i.id}
                      className="statusItems hover"
                      onClick={() => changeHandler('status', i.value)}
                    >
                      <div
                        className="task-board-menu-status"
                        style={{
                          backgroundColor: colors[i.color],
                        }}
                      >
                        {SvgSelector(i.label)}
                      </div>
                      <div>{i.name}</div>
                      <div className="task-board-menu-status-checked">
                        {data?.status === i.value && SvgSelector('checkMark')}
                      </div>
                    </div>
                  ))}
                </div>
              </AnimationSlide>
              <AnimationSlide
                show={openedMenu === 1}
                openMenu={openMenu}
                state="topToDown"
              >
                <div className="task-board-menu-date">
                  <Calendar toggle={() => openMenu(-1)} node={blockParent} />
                </div>
              </AnimationSlide>
              <AnimationSlide
                openMenu={openMenu}
                show={openedMenu}
                state="topToDown"
              >
                <div className="task-board-menu-date">
                  <ProOptionCard
                    closeMenu={openMenu}
                    proText="Want to add deadline? Sign up for a tariff level right now!"
                  />
                </div>
              </AnimationSlide>
              <AnimationSlide
                show={openedMenu === 2}
                openMenu={openMenu}
                state="topToDown"
              >
                <div className="popupMenuColorPanel task-board-menu-color-container">
                  <TagMenu task={blockParent} directReq={false} />
                </div>
              </AnimationSlide>
              <AnimationSlide
                show={openedMenu === 3}
                openMenu={openMenu}
                state="topToDown"
              >
                <div className="task-board-sharing">
                  <FloatMenuAssignment
                    layer={treeData[blockParent]}
                    taskBoard
                  />
                </div>
              </AnimationSlide>
              <AnimationSlide
                show={openedMenu === 5}
                openMenu={openMenu}
                state="topToDown"
              >
                <div className="task-board-invite">
                  <FloatMenuSharing
                    node={data?.id}
                    shareLayer={shareLayer}
                    setInviteModal={setInviteModal}
                  />
                </div>
              </AnimationSlide>
              <AnimationSlide
                show={openedMenu === 6}
                openMenu={openMenu}
                state="topToDown"
              >
                <ModalExport
                  openModal={openMenu}
                  boardID={boardId}
                  treeRef={treeRef}
                  boardName={treeData[boardId]?.title[0].text}
                />
              </AnimationSlide>
              <AnimationSlide
                show={openedMenu === 10}
                openMenu={openMenu}
                state="topToDown"
              >
                <div className="task-board-menu-resize">
                  <div>
                    {sendOptions.map((i) => (
                      <div key={i.id}>
                        <div className="task-board-resize-label">
                          {SvgSelector(i.label)}
                        </div>
                        <div>{i.name}</div>
                        <div className="task-board-menu-resize-separator" />
                      </div>
                    ))}
                  </div>
                </div>
              </AnimationSlide>
            </div>
          </div>
        </div>
        <div className={`boardWrapper ${fullScr ? 'fullScrBoardWrapper' : ''}`}>
          {data?.emoji && (
            <div
              className="task-board-show-emoji cursor-pointer"
              onClick={() => setShowEmoji(true)}
            >
              <Emoji unified={data?.emoji} size="60" />
            </div>
          )}
          <AnimationSlide
            openMenu={setShowEmoji}
            show={showEmoji}
            state="topToDown"
          >
            <div className="task-board-emoji-container" style={{ zIndex: 100 }}>
              <EmojiPicker selectEmoji={setEmoji} argument="emoji" />
            </div>
          </AnimationSlide>
          <div className="task-board-timer-container">
            {SvgSelector('timer', 'boardTimer')}
            <div className="font-14-med task-board-timer-text">
              Modified at{' '}
              {treeData[blockParent]?.modified_at
                ? formatDate(treeData[blockParent].modified_at)
                : 'a few minutes ago'}
            </div>
          </div>
          <input
            className="font-h1 task-board-title"
            readOnly={userAccess !== 'WRITE'}
            value={title || 'Title'}
            onChange={(e) => debounceAction(e.target.value)}
          />
          {note && (
            <TaskNote
              setData={setNote}
              data={note}
              selectedNode={blockParent}
              defaultId={defaultId}
            />
          )}
          {!note && <div className="task-board-loading">Loading ...</div>}
        </div>
      </div>
    </div>
  );
}

export default TaskBoard;
