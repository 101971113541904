import axios from 'axios';

// const DEBUG = process.env.NODE_ENV === 'development';

const APIKit = axios.create({
  // production => baseURL: "https://i.layer.cafe/",
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
});

APIKit.interceptors.request.use(
  (config) => {
    const cfg = config;
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('csrftoken='))
      ?.split('=')[1];
    cfg.headers['X-CSRFToken'] = cookieValue;
    cfg.withCredentials = true;

    return cfg;
  },
  (error) =>
    // if (DEBUG)console.error("✉️ ", error);

    Promise.reject(error),
);

APIKit.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status === 401) {
      if (err?.response) {
        // if (DEBUG) window.location.href = 'http://i.xolbia.com/accounts/login/';
        window.location.href = '/accounts/login/';
        localStorage.clear();
        return window.location.href;
      }
    }

    if (err?.response.status === 402) {
      if (err.response) {
        window.location.href = '/tariff/';
        return window.location.href;
      }
    }
    // return Promise.reject(err.response);

    if (err?.code === 'ECONNABORTED')
      return Promise.reject(new Error('No connection'));
    return Promise.reject(err);
  },
);

export default APIKit;
