import { useCallback, useEffect } from 'react';

export default function useKeyPress(callback) {
  const keydownHandler = useCallback(
    (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        !event.shiftKey &&
        event.code === 'KeyZ'
      )
        callback(1);
      else if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.code === 'KeyZ'
      )
        callback(2);
      else if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        event.code === 'KeyB'
      )
        callback('bugReport');
    },
    [callback],
  );

  useEffect(() => {
    window.addEventListener('keydown', keydownHandler);

    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [keydownHandler]);
}
